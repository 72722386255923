<h1 id="title" class="title">Login</h1>
<p class="sub-title">Benvenuto in Analisi del Rischio</p>
<div>

  <p class="d-flex justify-content-center">
    <img class="logo" src="/assets/images/logo.svg" alt=""><!--src="/assets/images/logo_oteposte.svg" posteitaliane -->
  </p>
  <div class="d-flex align-items-center slogan">
    <div>
      <div class="slogan"><i class="svg"></i></div>
      <div class="slogan">
        <span> - Analisi del Rischio</span>
      </div>
    </div>
  </div>
</div>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Perfetto!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Username:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           id="input-email"
           placeholder="Username"
           fieldSize="large"
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')">
    <ng-container *ngIf="email.invalid && email.touched">
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
    </span>
    <nb-form-field>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           [type]="getInputType()"
           id="input-password"
           placeholder="Password"
           fieldSize="large"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <button nbSuffix nbButton ghost (click)="toggleShowPassword()" type='button'>
        <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                 pack="eva"
                 [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
        </nb-icon>
      </button>
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contain
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
    </nb-form-field>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
    Log In
  </button>
</form>
