/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseIterableBia} from '../model/successfulResponseIterableBia';
import {
	SuccessfulResponseIterableLinkPreclassificazioneCompliance
} from '../model/successfulResponseIterableLinkPreclassificazioneCompliance';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class BiaControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua l&#x27;aggiunta del mapping tra preclassificazione e perimetro di compliance
	 *
	 * @param codicePreclassificazione codice classificazione
	 * @param nomePerimetro nome perimetro Bia
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public addMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public addMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public addMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public addMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codicePreclassificazione === null || codicePreclassificazione === undefined) {
			throw new Error('Required parameter codicePreclassificazione was null or undefined when calling addMappingPreclassificazionePerimetro.');
		}

		if (nomePerimetro === null || nomePerimetro === undefined) {
			throw new Error('Required parameter nomePerimetro was null or undefined when calling addMappingPreclassificazionePerimetro.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codicePreclassificazione !== undefined && codicePreclassificazione !== null) {
			queryParameters = queryParameters.set('codicePreclassificazione', <any>codicePreclassificazione);
		}
		if (nomePerimetro !== undefined && nomePerimetro !== null) {
			queryParameters = queryParameters.set('nomePerimetro', <any>nomePerimetro);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/bia/mappingPreclassificazionePerimetro`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la rimozione del mapping tra preclassificazione e perimetro di compliance
	 *
	 * @param codicePreclassificazione codice classificazione
	 * @param nomePerimetro nome perimetro Bia
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public deleteMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public deleteMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public deleteMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codicePreclassificazione === null || codicePreclassificazione === undefined) {
			throw new Error('Required parameter codicePreclassificazione was null or undefined when calling deleteMappingPreclassificazionePerimetro.');
		}

		if (nomePerimetro === null || nomePerimetro === undefined) {
			throw new Error('Required parameter nomePerimetro was null or undefined when calling deleteMappingPreclassificazionePerimetro.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codicePreclassificazione !== undefined && codicePreclassificazione !== null) {
			queryParameters = queryParameters.set('codicePreclassificazione', <any>codicePreclassificazione);
		}
		if (nomePerimetro !== undefined && nomePerimetro !== null) {
			queryParameters = queryParameters.set('nomePerimetro', <any>nomePerimetro);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/bia/mappingPreclassificazionePerimetro`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista BIA validate in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param id Imposta filtro su campo id
	 * @param projectCode Imposta filtro su campo projectCode
	 * @param projectName Imposta filtro su campo projectName
	 * @param biaCode Imposta filtro su campo biaCode
	 * @param fromDataCompilazione Imposta filtro per le analisi a partire dalla data fromDataCompilazione
	 * @param toDataCompilazione Imposta filtro per le analisi fino alla data toDataCompilazione
	 * @param fromDataValidazione Imposta filtro per le analisi a partire dalla data fromDataValidazione
	 * @param toDataValidazione Imposta filtro per le analisi fino alla data toDataValidazione
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllBia(page?: number, size?: number, id?: number, projectCode?: string, projectName?: string, biaCode?: number, fromDataCompilazione?: Date, toDataCompilazione?: Date, fromDataValidazione?: Date, toDataValidazione?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableBia>;
	public getAllBia(page?: number, size?: number, id?: number, projectCode?: string, projectName?: string, biaCode?: number, fromDataCompilazione?: Date, toDataCompilazione?: Date, fromDataValidazione?: Date, toDataValidazione?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableBia>>;
	public getAllBia(page?: number, size?: number, id?: number, projectCode?: string, projectName?: string, biaCode?: number, fromDataCompilazione?: Date, toDataCompilazione?: Date, fromDataValidazione?: Date, toDataValidazione?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableBia>>;
	public getAllBia(page?: number, size?: number, id?: number, projectCode?: string, projectName?: string, biaCode?: number, fromDataCompilazione?: Date, toDataCompilazione?: Date, fromDataValidazione?: Date, toDataValidazione?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (id !== undefined && id !== null) {
			queryParameters = queryParameters.set('id', <any>id);
		}
		if (projectCode !== undefined && projectCode !== null) {
			queryParameters = queryParameters.set('projectCode', <any>projectCode);
		}
		if (projectName !== undefined && projectName !== null) {
			queryParameters = queryParameters.set('projectName', <any>projectName);
		}
		if (biaCode !== undefined && biaCode !== null) {
			queryParameters = queryParameters.set('biaCode', <any>biaCode);
		}
		if (fromDataCompilazione !== undefined && fromDataCompilazione !== null) {
			queryParameters = queryParameters.set('fromDataCompilazione', <any>fromDataCompilazione.toISOString());
		}
		if (toDataCompilazione !== undefined && toDataCompilazione !== null) {
			queryParameters = queryParameters.set('toDataCompilazione', <any>toDataCompilazione.toISOString());
		}
		if (fromDataValidazione !== undefined && fromDataValidazione !== null) {
			queryParameters = queryParameters.set('fromDataValidazione', <any>fromDataValidazione.toISOString());
		}
		if (toDataValidazione !== undefined && toDataValidazione !== null) {
			queryParameters = queryParameters.set('toDataValidazione', <any>toDataValidazione.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableBia>('get', `${this.basePath}/api/v1/bia`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la rimozione del mapping tra preclassificazione e perimetro di compliance
	 *
	 * @param codicePreclassificazione codice classificazione
	 * @param nomePerimetro nome perimetro Bia
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableLinkPreclassificazioneCompliance>;
	public getAllMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableLinkPreclassificazioneCompliance>>;
	public getAllMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableLinkPreclassificazioneCompliance>>;
	public getAllMappingPreclassificazionePerimetro(codicePreclassificazione: string, nomePerimetro: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codicePreclassificazione === null || codicePreclassificazione === undefined) {
			throw new Error('Required parameter codicePreclassificazione was null or undefined when calling getAllMappingPreclassificazionePerimetro.');
		}

		if (nomePerimetro === null || nomePerimetro === undefined) {
			throw new Error('Required parameter nomePerimetro was null or undefined when calling getAllMappingPreclassificazionePerimetro.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codicePreclassificazione !== undefined && codicePreclassificazione !== null) {
			queryParameters = queryParameters.set('codicePreclassificazione', <any>codicePreclassificazione);
		}
		if (nomePerimetro !== undefined && nomePerimetro !== null) {
			queryParameters = queryParameters.set('nomePerimetro', <any>nomePerimetro);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableLinkPreclassificazioneCompliance>('get', `${this.basePath}/api/v1/bia/mappingPreclassificazionePerimetro`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista BIA validate in anagrafica
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public populateBia(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public populateBia(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public populateBia(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public populateBia(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/bia/populateBia`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get delle informazioni per ciasciuna bia in anagrafica
	 *
	 * @param overrideInitialized Flag per effettuare forzatamente l&#x27;aggiornamento delle informazioni
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public populateInformazioni(overrideInitialized?: boolean, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public populateInformazioni(overrideInitialized?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public populateInformazioni(overrideInitialized?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public populateInformazioni(overrideInitialized?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (overrideInitialized !== undefined && overrideInitialized !== null) {
			queryParameters = queryParameters.set('overrideInitialized', <any>overrideInitialized);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/bia/populateInformazioni`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
