/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {AssociateAppAssetAnalisiDTO} from '../model/associateAppAssetAnalisiDTO';
import {SuccessfulResponseApplicazione} from '../model/successfulResponseApplicazione';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseIterableApplicazione} from '../model/successfulResponseIterableApplicazione';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class ApplicazioneControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Aggiunge una tipologia ad un&#x27;applicazione
	 *
	 * @param codice campo codice
	 * @param codicePacchetto campo codicePacchetto
	 * @param tipologia campo tipologia
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public addTipologiaToApplicazione(codice?: string, codicePacchetto?: string, tipologia?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public addTipologiaToApplicazione(codice?: string, codicePacchetto?: string, tipologia?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public addTipologiaToApplicazione(codice?: string, codicePacchetto?: string, tipologia?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public addTipologiaToApplicazione(codice?: string, codicePacchetto?: string, tipologia?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (codicePacchetto !== undefined && codicePacchetto !== null) {
			queryParameters = queryParameters.set('codicePacchetto', <any>codicePacchetto);
		}
		if (tipologia !== undefined && tipologia !== null) {
			queryParameters = queryParameters.set('tipologia', <any>tipologia);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('put', `${this.basePath}/api/v1/applicazione/addtipologia`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione anagrafica tra app ed una lista di assets
	 *
	 * @param body Associazioni tra app ed assets
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public assegnaAssetAdAppDefault(body: AssociateAppAssetAnalisiDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public assegnaAssetAdAppDefault(body: AssociateAppAssetAnalisiDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public assegnaAssetAdAppDefault(body: AssociateAppAssetAnalisiDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public assegnaAssetAdAppDefault(body: AssociateAppAssetAnalisiDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling assegnaAssetAdAppDefault.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/applicazione/associaAssets`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista dei Applicazione in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice Imposta filtro su campo codice
	 * @param codicePacchetto Imposta filtro su campo codicePacchetto
	 * @param nome Imposta filtro su campo nome
	 * @param descrizione Imposta filtro su campo descrizione
	 * @param nomePacchetto Imposta filtro su campo nomePacchetto
	 * @param tipo Imposta filtro su campo tipo
	 * @param layer Imposta filtro su campo layer
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllApplicazione(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, descrizione?: string, nomePacchetto?: string, tipo?: string, layer?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableApplicazione>;
	public getAllApplicazione(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, descrizione?: string, nomePacchetto?: string, tipo?: string, layer?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableApplicazione>>;
	public getAllApplicazione(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, descrizione?: string, nomePacchetto?: string, tipo?: string, layer?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableApplicazione>>;
	public getAllApplicazione(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, descrizione?: string, nomePacchetto?: string, tipo?: string, layer?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (codicePacchetto !== undefined && codicePacchetto !== null) {
			queryParameters = queryParameters.set('codicePacchetto', <any>codicePacchetto);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (descrizione !== undefined && descrizione !== null) {
			queryParameters = queryParameters.set('descrizione', <any>descrizione);
		}
		if (nomePacchetto !== undefined && nomePacchetto !== null) {
			queryParameters = queryParameters.set('nomePacchetto', <any>nomePacchetto);
		}
		if (tipo !== undefined && tipo !== null) {
			queryParameters = queryParameters.set('tipo', <any>tipo);
		}
		if (layer !== undefined && layer !== null) {
			queryParameters = queryParameters.set('layer', <any>layer);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableApplicazione>('get', `${this.basePath}/api/v1/applicazione/all`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get dell&#x27;applicazione con codice {codice} e codicePacchetto
	 *
	 * @param codiceApplicazione Codice dell&#x27;applicazione
	 * @param codicePacchetto Codice del pacchetto
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getApplicazioneByCodice(codiceApplicazione: string, codicePacchetto?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseApplicazione>;
	public getApplicazioneByCodice(codiceApplicazione: string, codicePacchetto?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseApplicazione>>;
	public getApplicazioneByCodice(codiceApplicazione: string, codicePacchetto?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseApplicazione>>;
	public getApplicazioneByCodice(codiceApplicazione: string, codicePacchetto?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codiceApplicazione === null || codiceApplicazione === undefined) {
			throw new Error('Required parameter codiceApplicazione was null or undefined when calling getApplicazioneByCodice.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codiceApplicazione !== undefined && codiceApplicazione !== null) {
			queryParameters = queryParameters.set('codiceApplicazione', <any>codiceApplicazione);
		}
		if (codicePacchetto !== undefined && codicePacchetto !== null) {
			queryParameters = queryParameters.set('codicePacchetto', <any>codicePacchetto);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseApplicazione>('get', `${this.basePath}/api/v1/applicazione`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la populate anagrafica dal cirm
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public populateFromCirm(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public populateFromCirm(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public populateFromCirm(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public populateFromCirm(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/applicazione/populateFromCirm`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
