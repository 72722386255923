<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()"><img class="logo-adr" src="../assets/images/logo.svg" alt="">

        </a>

        <div class="d-flex align-items-center logo-text">

            <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-dark-75 font-size-h6 mb-0">ADR</span>
                <p class="text-muted text-hover-primary font-weight-bold">Analisi del Rischio</p>
            </div>
        </div>
    </div>
    <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>-->
    <div *ngIf="showCompaniesList" fullWidth class="col-sm-3 ml-3">
        <nb-form-field>
            <nb-icon nbPrefix icon="briefcase-outline" pack="eva"></nb-icon>
            <nb-select [(ngModel)]="this.selectedCompanyId" (selectedChange)="this.onCurrentCompanyChange($event)"
                       size="large">
                <nb-option *ngFor="let item of companies" [value]="item.id">{{item.businessName}}</nb-option>
            </nb-select>
        </nb-form-field>
    </div>

</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action class="fullscreen ie" *ngIf="isFullscreen == false; else loggedOut" (click)="toggleFullscreen()"
                   title="Schermo intero">
            <nb-icon icon="expand-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
        </nb-action>
        <ng-template class="fullscreen ie" #loggedOut>
            <nb-action (click)="toggleFullscreen()">
                <nb-icon icon="collapse-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
            </nb-action>
        </ng-template>
<!--        <nb-action icon="bell-outline"-->
<!--                   [badgeText]="this.numRichieste"-->
<!--                   badgePosition="top right"-->
<!--                   link="/pages/users/requests"-->
<!--                   [badgeStatus]="this.numRichieste>0?'danger':'default'">-->
<!--        </nb-action>-->
        <nb-action *nbIsGranted="['AUTHENTICATED', 'user']">
            <nb-user [nbContextMenu]="userMenu"
                     [name]="user?.fullname"
                     [showInitials]="true"
                     [onlyPicture]="false">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
