import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';
import {Globals} from '../../globals';
import {
	Analisi,
	AnalisiDTO,
	Applicazione,
	ApplicazioneKey, Asset,
	AssociateAppAssetAnalisiDTO,
	AssociateAppProcessoAnalisiDTO,
	MinacciaView,
	OverrideProbabilitaMinaccia,
	OverrideVulnerabilitaDTO, PerimetroDiCompliance,
	ProcessoDTO
} from '../apis';

import {tap} from 'rxjs/operators';
import {Observable} from "rxjs/Observable";
import {VulnerabilitaView} from '../apis/model/vulnerabilitaView';
import {TripleTextTextNumber} from '../models/TripleTextTextNumber';
import {ApplicazioneDTO} from '../apis/model/applicazioneDTO';

@Injectable()
export class ApiService {

	public applicationUpdated: EventEmitter<boolean> = new EventEmitter();
	public processUpdated: EventEmitter<boolean> = new EventEmitter();
	public checklistUpdated: EventEmitter<boolean> = new EventEmitter();
	public assetUpdated: EventEmitter<boolean> = new EventEmitter();
	public analisiUpdated: EventEmitter<boolean> = new EventEmitter();
	public selectAllTableThreats: EventEmitter<TripleTextTextNumber> = new EventEmitter();
	public selectAllCurrentTable: EventEmitter<TripleTextTextNumber> = new EventEmitter();
	public appAnalysisRemove: EventEmitter<ApplicazioneKey> = new EventEmitter();
	public infoAnalysisRemove: EventEmitter<string> = new EventEmitter();
	public processAnalysisRemove: EventEmitter<string> = new EventEmitter();
	public applyToAllVulnerability: EventEmitter<any> = new EventEmitter();

	public gradoDiApplicabilitaChanged: EventEmitter<any> = new EventEmitter();
	public gradoDiApplicabilitaChecklistChanged: EventEmitter<any> = new EventEmitter();

	protected dashboardApiUrl: string = environment.apiBaseEndpoint + environment.dashboardEndpoint;
	protected applicationApiUrl: string = environment.apiBaseEndpoint + environment.applicationEndpoint;
	protected processApiUrl: string = environment.apiBaseEndpoint + environment.processesEndpoint;
	protected analysisApiUrl: string = environment.apiBaseEndpoint + environment.analysisEndpoint;
	protected excelApiUrl: string = environment.apiBaseEndpoint + environment.excelEndpoint;

	protected header = new HttpHeaders({'Content-Type': 'application/json'});


	constructor(private http: HttpClient, public globals: Globals) {
	}

	createAnalisi(analisi: AnalisiDTO): Observable<any> {
		return this.http.post(
			this.analysisApiUrl, JSON.stringify(analisi), {headers: this.header, responseType: 'text'},
		).pipe(
			tap(res => {
				if (res) {
					this.analisiUpdated.emit(true);
				}
			}),
		);
	}

	createApplicazione(app: ApplicazioneDTO): Observable<any> {
		return this.http.post(
			this.applicationApiUrl, JSON.stringify(app), {headers: this.header, responseType: 'text'},
		).pipe(
			tap(res => {
				if (res) {
					this.applicationUpdated.emit(true);
				}
			}),
		);
	}

	createProcesso(processo: ProcessoDTO): Observable<any> {
		return this.http.post(
			this.processApiUrl, JSON.stringify(processo), {headers: this.header, responseType: 'text'},
		).pipe(
			tap(res => {
				if (res) {
					this.processUpdated.emit(true);
				}
			}),
		);
	}

	associateApplicazioniToAnalisi(appKeys: ApplicazioneKey[], codice: string): Observable<any> {
		return this.http.post(
			environment.apiBaseEndpoint + environment.appName +
			`/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioni`,
			JSON.stringify(appKeys), {headers: this.header, responseType: 'text'},
		);
	}

	associateProcessiToAnalisi(codice: string, processi: string[]): Observable<any> {
		return this.http.post(
			environment.apiBaseEndpoint + environment.appName +
			`/api/v1/analisi/${encodeURIComponent(String(codice))}/associateProcessi`,
			JSON.stringify(processi), {headers: this.header, responseType: 'text'},
		);
	}

	associateProcessiToAnalisiQueryString(codice: string, processi: string[]): Observable<any> {
		let p = '';
		if (processi && processi.length > 0)
			p = processi.join(',');

		return this.http.post(
			environment.apiBaseEndpoint + environment.appName +
			`/api/v1/analisi/${encodeURIComponent(String(codice))}/associateProcessi?processi=${p}`,
			null, {headers: this.header, responseType: 'text'},
		);
	}


	associateApplicazioniProcessiToAnalisi(codice: string, body: AssociateAppProcessoAnalisiDTO): Observable<any> {
		return this.http.post(
			environment.apiBaseEndpoint + environment.appName +
			`/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioniProcessi`,
			JSON.stringify(body), {headers: this.header, responseType: 'text'},
		);
	}

	associateApplicazioniAssetsToAnalisi(codice: string, body: AssociateAppAssetAnalisiDTO[]): Observable<any> {
		return this.http.post(
			environment.apiBaseEndpoint + environment.appName +
			`/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioniAssets`,
			JSON.stringify(body), {headers: this.header, responseType: 'text'},
		);
	}

	analisiToDTO(analisi: Analisi): AnalisiDTO {
		const analisiDTO = <AnalisiDTO>{};
		analisiDTO.data = analisi.data;
		analisiDTO.data.setHours(analisi.data.getHours() + 2);
		analisiDTO.dominioDiAnalisi = analisi.dominioDiAnalisi;
		analisiDTO.codice = analisi.codice;
		analisiDTO.nome = analisi.nome;
		analisiDTO.cliente = analisi.cliente.nome;
		analisiDTO.raf = analisi.raf;
		analisiDTO.noteRaf = analisi.noteRaf;
		return analisiDTO;
	}

	vulnerabilitaToDTO(v: VulnerabilitaView): OverrideVulnerabilitaDTO {
		const vDTO = <OverrideVulnerabilitaDTO>{};
		vDTO.applicabilita = v.applicabilita;
		vDTO.idVulnerabilita = v.vulnerabilita.id;
		vDTO.noteDiLavoro = v.noteDiLavoro ? v.noteDiLavoro : v.vulnerabilita.noteDiLavoroAutomatismo;
		vDTO.overrideValore = v.overrideValoreVulnerabilita ? v.overrideValoreVulnerabilita : v.vulnerabilita.valore;
		return vDTO;
	}

	minacciaViewToOverrideProbabilitaMinaccia(v: MinacciaView): OverrideProbabilitaMinaccia {
		const vDTO = <OverrideProbabilitaMinaccia>{};
		vDTO.idMinaccia = v.minaccia.id;
		vDTO.overrideProbabilita = v.overrideProbabilita;
		return vDTO;
	}

	isEqualsApplicazioneKey(a: ApplicazioneKey, b: ApplicazioneKey): boolean {
		return a.codice === b.codice && a.codicePacchetto === b.codicePacchetto;
	}

	isEqualsApplicazione(a: Applicazione, b: Applicazione): boolean {
		return this.isEqualsApplicazioneKey(a.key, b.key);
	}

	checkStatusStepsAnalisi(analisi: Analisi, stepStates: number[]) {
		if (analisi)
			stepStates[0] = 1;
		if (analisi.processi.length > 0)
			stepStates[1] = 1;
		if (analisi.applicazioni.length > 0)
			stepStates[2] = 1;
		if (analisi.mapAppAssetsView.length > 0)
			stepStates[3] = 1;
		if (analisi.applicazioni.length > 0 && analisi.processi.length > 0)
			stepStates[4] = 1;
		if (stepStates[4] > 0)
			stepStates[5] = 1;
		if (stepStates[5] > 0)
			stepStates[6] = 1;
	}


	checkStatusStepsEnterprice(analisi: Analisi, stepStates: number[]) {
		if (analisi)
			stepStates[0] = 1;
		if (analisi.processi.length > 0)
			stepStates[1] = 1;
		if (analisi.applicazioni.length > 0)
			stepStates[2] = 1;
		if (analisi.mapAppAssetsView.length > 0)
			stepStates[3] = 1;
	}

	checkStatusRP(analisi: Analisi, stepStates: number[]) {
		const analisiDTO = <AnalisiDTO>{};
		analisiDTO.data = analisi.data;
		if (this.globals.STATUS_IDS.get(analisi.stato) == this.globals.STATUS_IDS.get(this.globals.ASSOCIA_AP_INFO_ANALISI))
			stepStates[0] = 1;
		if (this.globals.STATUS_IDS.get(analisi.stato) == this.globals.STATUS_IDS.get(this.globals.ASSOCIA_PROCESSI_INFO_ANALISI))
			stepStates[1] = 1;
		if (this.globals.STATUS_IDS.get(analisi.stato) == this.globals.STATUS_IDS.get(this.globals.ASSOCIA_ASSET_INFO_ANALISI))
			stepStates[2] = 1;
		if (this.globals.STATUS_IDS.get(analisi.stato) == this.globals.STATUS_IDS.get(this.globals.ASSOCIA_PROCESSI_PERIMETRI_ANALISI))
			stepStates[2] = 1;
	}

	exportAnalysisExcel(codice: string, storico: boolean = false): Observable<any> {
		return this.http.get(this.excelApiUrl + encodeURIComponent(String(codice)) + '/export-re?storico='+storico,
			{headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
	}

	exportContromisureExcel(codice: string, storico: boolean = false): Observable<any> {
		return this.http.get(this.excelApiUrl + encodeURIComponent(String(codice)) + '/export-contromisure?storico='+storico,
			{headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
	}
	exportRequisitiPianoTrattamentoExcel(codice: string, storico: boolean = false): Observable<any> {
		return this.http.get(this.excelApiUrl + encodeURIComponent(String(codice)) + '/export-requisitipiano?storico='+storico,
			{headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
	}
	exportRischioExcel(codice: string, storico: boolean = false): Observable<any> {
		return this.http.get(this.excelApiUrl + encodeURIComponent(String(codice)) + '/export-rischi?storico='+storico,
			{headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
	}


    sortApplicazioniAnalisi(analisi: Analisi) {
		analisi.applicazioni = analisi.applicazioni.sort((a, b) => {
			if (a.key.codice == b.key.codice) {
				if (a.key.codicePacchetto == a.key.codicePacchetto) return 0;
				return a.key.codicePacchetto > a.key.codicePacchetto ? 1 : -1;
			} else {
				return a.key.codice > b.key.codice ? 1 : -1;
			}
		})
    }

	sortProcessiAnalisi(analisi: Analisi) {
		// Ordino colonne tabella
		analisi.processi = analisi.processi.sort((a, b) => {
			if (a.nome == b.nome) return 0;
			return a.nome > b.nome ? 1 : -1;
		})

    }

	sortInformazioniAnalisi(analisi: Analisi) {
		// Ordino colonne tabella
		analisi.informazioni = analisi.informazioni.sort((a, b) => {
			if (a.codice == b.codice) return 0;
			return a.codice > b.codice ? 1 : -1;
		})
	}

	sortPerimeters(perimeters: Array<PerimetroDiCompliance>) {
		// Ordino colonne tabella
		return perimeters.sort((a, b) => {
			if (a.codice == b.codice) return 0;
			return a.codice > b.codice ? 1 : -1;
		})
	}

	sortAssetAnalisi(assets: Asset[]) {
		return assets.sort((a, b) => {
			if (a.nome == b.nome) return 0;
			return a.nome > b.nome ? 1 : -1;
		})
	}
}

export function compareTableRowsByValuesFunction (direction: any, fa: any, fb: any, a: any, b: any) {
	let first = a;
	let second = b;
	if (first < second) {
		return -1 * direction;
	}
	if (first > second) {
		return direction;
	}
	return 0;
}


