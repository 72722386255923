/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OverrideVulnerabilitaDTO {
	idVulnerabilita?: string;
	noteDiLavoro?: string;
	applicabilita?: boolean;
	overrideValore?: number;
}