import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NbToastrConfig, NbToastrService} from '@nebular/theme';

export class ToasterEvent {
	constructor(public type?: string,
							public title?: string,
							public message?: any,
							public special?: any) {
	}
}

@Injectable({
	providedIn: 'root',
})
export class ToasterEmitterService {

	toasterEmitter: EventEmitter<ToasterEvent> = new EventEmitter<ToasterEvent>();
	closeModalEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();


	constructor(private translate: TranslateService,
							private toastrService: NbToastrService) {
	}

	public emitHttpError(error: HttpErrorResponse, config: Partial<NbToastrConfig> = {}) {

		let message = error.message;
		if(error.error.message) {
			message = error.error.message;
		}else if(error.error.status && error.error.status.message) {
			message = error.error.status.message;
		}

		this.toastrService.danger(
			message,
			'Error', config);
		this.closeModalEmitter.emit(true);
	}

	public emit(toaster: ToasterEvent) {

		if (toaster.type === 'success')
			this.toastrService.success(toaster.message, toaster.title);
		else if (toaster.type === 'warning')
			this.toastrService.warning(toaster.message, toaster.title);
		else if (toaster.type === 'danger')
			this.toastrService.danger(toaster.message, toaster.title);
		else if (toaster.type === 'info')
			this.toastrService.info(toaster.message, toaster.title);

		this.toasterEmitter.emit(toaster);
	}

	public emitHttpTranslated(res: any) {
		if (res.body['status'] !== undefined && res.body['status'].id > 0) {
			const status = res.body['status'];
			const resp = 'RESPONSES.' + status.code;
			const value = this.translate.get(resp)['value'];
			if (value !== resp) {
				this.translate.get('RESPONSES.' + status.code)
					.subscribe((s: string) => {
						if (res.body['data'] !== null) {
							s = s + ' [' + res.body['data'] + ']';
						}
						this.toastrService.danger(s, 'Errore');
						this.closeModalEmitter.emit(true);
					}).unsubscribe();
			} else if (status['message'] !== undefined) {
				this.toastrService.danger(status.message, 'Errore', {});
				this.closeModalEmitter.emit(true);
			} else {
				this.toastrService.danger(resp, 'Errore', {});
				this.closeModalEmitter.emit(true);
			}
		}
	}
}

