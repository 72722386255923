/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {RequisitoApp} from './requisitoApp';
import {RequisitoAsset} from './requisitoAsset';
import {RequisitoProcesso} from './requisitoProcesso';

export interface ChecklistDTO {
	id?: number;
	codiceAnalisi?: string;
	nome?: string;
	descrizione?: string;
	ownerId?: number;
	stato?: ChecklistDTO.StatoEnum;
	requisitiApp?: Array<RequisitoApp>;
	requisitiAsset?: Array<RequisitoAsset>;
	requisitiProcesso?: Array<RequisitoProcesso>;
}

export namespace ChecklistDTO {
	export type StatoEnum = 'IN_COMPILAZIONE' | 'INVIATA' | 'IN_CARICO_OWNER' | 'COMPLETATA' | 'CHIUSA' | 'RIFIUTATA';
	export const StatoEnum = {
		INCOMPILAZIONE: 'IN_COMPILAZIONE' as StatoEnum,
		INVIATA: 'INVIATA' as StatoEnum,
		INCARICOOWNER: 'IN_CARICO_OWNER' as StatoEnum,
		COMPLETATA: 'COMPLETATA' as StatoEnum,
		CHIUSA: 'CHIUSA' as StatoEnum,
		RIFIUTATA: 'RIFIUTATA' as StatoEnum
	};
}