/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StoricoModificheEntry {
	stato?: StoricoModificheEntry.StatoEnum;
	descrizione?: string;
	action?: StoricoModificheEntry.ActionEnum;
	userId?: number;
	timestamp?: Date;
}

export namespace StoricoModificheEntry {
	export type StatoEnum = 'IN_COMPILAZIONE' | 'INVIATA' | 'IN_CARICO_OWNER' | 'COMPLETATA' | 'CHIUSA' | 'RIFIUTATA';
	export const StatoEnum = {
		INCOMPILAZIONE: 'IN_COMPILAZIONE' as StatoEnum,
		INVIATA: 'INVIATA' as StatoEnum,
		INCARICOOWNER: 'IN_CARICO_OWNER' as StatoEnum,
		COMPLETATA: 'COMPLETATA' as StatoEnum,
		CHIUSA: 'CHIUSA' as StatoEnum,
		RIFIUTATA: 'RIFIUTATA' as StatoEnum
	};
	export type ActionEnum = 'CREAZIONE_CHECKLIST' | 'EDIT_CHECKLIST';
	export const ActionEnum = {
		CREAZIONECHECKLIST: 'CREAZIONE_CHECKLIST' as ActionEnum,
		EDITCHECKLIST: 'EDIT_CHECKLIST' as ActionEnum
	};
}