/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {SuccessfulResponseString} from '../model/successfulResponseString';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class SwitchControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Disattiva lo scheduled job per il popolamento della lista delle BIA dalla fonte BIA
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public switchPopulateBia(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseString>;
	public switchPopulateBia(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseString>>;
	public switchPopulateBia(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseString>>;
	public switchPopulateBia(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseString>('post', `${this.basePath}/api/v1/switch/populateBia`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Disattiva lo scheduled job per il popolamento AP dalla fonte CIRM
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public switchPopulateCirm(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseString>;
	public switchPopulateCirm(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseString>>;
	public switchPopulateCirm(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseString>>;
	public switchPopulateCirm(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseString>('post', `${this.basePath}/api/v1/switch/populateCirm`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Disattiva lo scheduled job per il popolamento delle informazioni dalla fonte BIA
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public switchPopulateFromInformazioniBia(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseString>;
	public switchPopulateFromInformazioniBia(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseString>>;
	public switchPopulateFromInformazioniBia(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseString>>;
	public switchPopulateFromInformazioniBia(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseString>('post', `${this.basePath}/api/v1/switch/populateInformazioniBia`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Disattiva lo scheduled job per il popolamento Coperture dalla fonte Ote per le coperture del SecurityByDesign
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public switchPopulateFromSecurityByDesign(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseString>;
	public switchPopulateFromSecurityByDesign(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseString>>;
	public switchPopulateFromSecurityByDesign(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseString>>;
	public switchPopulateFromSecurityByDesign(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseString>('post', `${this.basePath}/api/v1/switch/populateSecurityByDesign`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Disattiva lo scheduled job per il popolamento da fonte Ote per la libreria delle contromisure
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public switchPopulateOte(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseString>;
	public switchPopulateOte(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseString>>;
	public switchPopulateOte(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseString>>;
	public switchPopulateOte(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseString>('post', `${this.basePath}/api/v1/switch/populateOTE`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Disattiva lo scheduled job per il popolamento coperture dalla fonte PPSWEB
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public switchPopulatePPSWEB(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseString>;
	public switchPopulatePPSWEB(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseString>>;
	public switchPopulatePPSWEB(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseString>>;
	public switchPopulatePPSWEB(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseString>('post', `${this.basePath}/api/v1/switch/populatePPSWEB`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
