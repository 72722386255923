/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {AggiornaRichiestaRequisitoDTO} from '../model/aggiornaRichiestaRequisitoDTO';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseLeMieRichiesteDTO} from '../model/successfulResponseLeMieRichiesteDTO';
import {SuccessfulResponseRichiesta} from '../model/successfulResponseRichiesta';
import {SuccessfulResponseStatisticheRichiesteDTO} from '../model/successfulResponseStatisticheRichiesteDTO';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class RichiestaControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua l&#x27;aggiornamento di una copertura da parte dell&#x27;owner di un requisito
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public aggiornaRichiestaRequisito(body: AggiornaRichiestaRequisitoDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public aggiornaRichiestaRequisito(body: AggiornaRichiestaRequisitoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public aggiornaRichiestaRequisito(body: AggiornaRichiestaRequisitoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public aggiornaRichiestaRequisito(body: AggiornaRichiestaRequisitoDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling aggiornaRichiestaRequisito.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/richiesta/aggiornaRichiestaRequisito`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il delete della richiesta con id {id}
	 *
	 * @param id
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public delRichiestaById(id: number, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public delRichiestaById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public delRichiestaById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public delRichiestaById(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (id === null || id === undefined) {
			throw new Error('Required parameter id was null or undefined when calling delRichiestaById.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/richiesta/${encodeURIComponent(String(id))}`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get delle richieste aperte per l&#x27;utente
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getLeMieRichieste(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseLeMieRichiesteDTO>;
	public getLeMieRichieste(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseLeMieRichiesteDTO>>;
	public getLeMieRichieste(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseLeMieRichiesteDTO>>;
	public getLeMieRichieste(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseLeMieRichiesteDTO>('get', `${this.basePath}/api/v1/richiesta/leMieRichieste`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della richiesta con id {id}
	 *
	 * @param id
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRichiestaById(id: number, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseRichiesta>;
	public getRichiestaById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseRichiesta>>;
	public getRichiestaById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseRichiesta>>;
	public getRichiestaById(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (id === null || id === undefined) {
			throw new Error('Required parameter id was null or undefined when calling getRichiestaById.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseRichiesta>('get', `${this.basePath}/api/v1/richiesta/${encodeURIComponent(String(id))}`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get delle statistiche delle richieste aperte dall&#x27;utente autenticato
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getStatisticheRichieste(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseStatisticheRichiesteDTO>;
	public getStatisticheRichieste(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseStatisticheRichiesteDTO>>;
	public getStatisticheRichieste(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseStatisticheRichiesteDTO>>;
	public getStatisticheRichieste(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseStatisticheRichiesteDTO>('get', `${this.basePath}/api/v1/richiesta/statistiche`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
