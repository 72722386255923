/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinkRequisitoAssetAnalisiChecklistDTO {
	codiceRequisito?: string;
	codiceAsset?: string;
	noteDiLavoro?: string;
	gradoDiApplicabilitaIniziale?: LinkRequisitoAssetAnalisiChecklistDTO.GradoDiApplicabilitaInizialeEnum;
	gradoDiApplicabilitaFinale?: LinkRequisitoAssetAnalisiChecklistDTO.GradoDiApplicabilitaFinaleEnum;
	createdAt?: Date;
	modifiedAt?: Date;
}

export namespace LinkRequisitoAssetAnalisiChecklistDTO {
	export type GradoDiApplicabilitaInizialeEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoDiApplicabilitaInizialeEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoDiApplicabilitaInizialeEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoDiApplicabilitaInizialeEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoDiApplicabilitaInizialeEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoDiApplicabilitaInizialeEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoDiApplicabilitaInizialeEnum
	};
	export type GradoDiApplicabilitaFinaleEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoDiApplicabilitaFinaleEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoDiApplicabilitaFinaleEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoDiApplicabilitaFinaleEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoDiApplicabilitaFinaleEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoDiApplicabilitaFinaleEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoDiApplicabilitaFinaleEnum
	};
}