/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {MultipartFile} from '../model/multipartFile';
import {User} from '../model/user';
import {UserPrincipal} from '../model/userPrincipal';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class FileUploadControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 *
	 *
	 * @param userPrincipal
	 * @param file
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public uploadForm(userPrincipal?: UserPrincipal, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public uploadForm(userPrincipal?: UserPrincipal, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public uploadForm(userPrincipal?: UserPrincipal, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public uploadForm(userPrincipal?: UserPrincipal, file?: Blob, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'multipart/form-data'
		];

		const canConsumeForm = this.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): void; };
		let useForm = false;
		let convertFormParamsToString = false;
		// use FormData to transmit files using content-type "multipart/form-data"
		// see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
		useForm = canConsumeForm;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		}

		if (userPrincipal !== undefined) {
			formParams = formParams.append('userPrincipal', <any>userPrincipal) as any || formParams;
		}
		if (file !== undefined) {
			formParams = formParams.append('file', <any>file) as any || formParams;
		}

		return this.httpClient.request<any>('post', `${this.basePath}/file/upload`,
			{
				body: convertFormParamsToString ? formParams.toString() : formParams,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 *
	 *
	 * @param file
	 * @param user
	 * @param roleName
	 * @param idCompany
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public uploadMultipleFilesForm(file: Array<MultipartFile>, user?: User, roleName?: string, idCompany?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public uploadMultipleFilesForm(file: Array<MultipartFile>, user?: User, roleName?: string, idCompany?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public uploadMultipleFilesForm(file: Array<MultipartFile>, user?: User, roleName?: string, idCompany?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public uploadMultipleFilesForm(file: Array<MultipartFile>, user?: User, roleName?: string, idCompany?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (file === null || file === undefined) {
			throw new Error('Required parameter file was null or undefined when calling uploadMultipleFiles.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (file) {
			file.forEach((element) => {
				queryParameters = queryParameters.append('file', <any>element);
			})
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'multipart/form-data'
		];

		const canConsumeForm = this.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): void; };
		let useForm = false;
		let convertFormParamsToString = false;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		}

		if (user !== undefined) {
			formParams = formParams.append('user', <any>user) as any || formParams;
		}
		if (roleName !== undefined) {
			formParams = formParams.append('roleName', <any>roleName) as any || formParams;
		}
		if (idCompany !== undefined) {
			formParams = formParams.append('idCompany', <any>idCompany) as any || formParams;
		}

		return this.httpClient.request<any>('post', `${this.basePath}/file/upload_files`,
			{
				body: convertFormParamsToString ? formParams.toString() : formParams,
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
