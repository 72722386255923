/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RequisitoAsset {
	codiceRequisito?: string;
	codiceAsset?: string;
	gradoIniziale?: RequisitoAsset.GradoInizialeEnum;
	gradoFinale?: RequisitoAsset.GradoFinaleEnum;
	noteDiLavoro?: string;
}

export namespace RequisitoAsset {
	export type GradoInizialeEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoInizialeEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoInizialeEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoInizialeEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoInizialeEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoInizialeEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoInizialeEnum
	};
	export type GradoFinaleEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoFinaleEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoFinaleEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoFinaleEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoFinaleEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoFinaleEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoFinaleEnum
	};
}