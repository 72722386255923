/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RequisitoAssetOwner {
	codiceRequisito?: string;
	codiceAsset?: string;
	idOwner?: number;
	gradoSuggerito?: RequisitoAssetOwner.GradoSuggeritoEnum;
}

export namespace RequisitoAssetOwner {
	export type GradoSuggeritoEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoSuggeritoEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoSuggeritoEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoSuggeritoEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoSuggeritoEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoSuggeritoEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoSuggeritoEnum
	};
}