/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AutomatismoDTO {
	id?: number;
	idVulnerabilita?: string;
	tag?: string;
	catasi?: AutomatismoDTO.CatasiEnum;
	infoCatasi?: string;
	owner?: string;
	descrizione?: string;
	noteDiLavoro?: string;
	applicabilita?: boolean;
}

export namespace AutomatismoDTO {
	export type CatasiEnum = 'SI' | 'false' | 'PARZIALE';
	export const CatasiEnum = {
		SI: 'SI' as CatasiEnum,
		False: 'false' as CatasiEnum,
		PARZIALE: 'PARZIALE' as CatasiEnum
	};
}