/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Evento {
	id?: number;
	tipo?: Evento.TipoEnum;
	livello?: Evento.LivelloEnum;
	data?: Date;
	messaggio?: string;
	link?: string;
	codiceAnalisi?: string;
}

export namespace Evento {
	export type TipoEnum = 'CAMBIO_VALORE_VULN';
	export const TipoEnum = {
		CAMBIOVALOREVULN: 'CAMBIO_VALORE_VULN' as TipoEnum
	};
	export type LivelloEnum = 'INFO' | 'WARNING' | 'ERROR';
	export const LivelloEnum = {
		INFO: 'INFO' as LivelloEnum,
		WARNING: 'WARNING' as LivelloEnum,
		ERROR: 'ERROR' as LivelloEnum
	};
}