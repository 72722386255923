/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseIterableRequisito} from '../model/successfulResponseIterableRequisito';
import {SuccessfulResponseIterableRequisitoTrasversale} from '../model/successfulResponseIterableRequisitoTrasversale';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class LibreriaContromisureControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua l&#x27;aggiunta di una contromisura trasversale
	 *
	 * @param codice Imposta filtro su campo codice
	 * @param codiceLayer Imposta filtro su campo codiceLayer
	 * @param gradoApplicabilita Imposta filtro su campo gradoApplicabilita
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public addNewContromisuraTrasversale(codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public addNewContromisuraTrasversale(codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public addNewContromisuraTrasversale(codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public addNewContromisuraTrasversale(codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (codiceLayer !== undefined && codiceLayer !== null) {
			queryParameters = queryParameters.set('codiceLayer', <any>codiceLayer);
		}
		if (gradoApplicabilita !== undefined && gradoApplicabilita !== null) {
			queryParameters = queryParameters.set('gradoApplicabilita', <any>gradoApplicabilita);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/contromisureTrasversali`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;aggiunta di una contromisura trasversale
	 *
	 * @param codice Imposta filtro su campo codice
	 * @param codiceLayer Imposta filtro su campo codiceLayer
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteContromisuraTrasversale(codice?: string, codiceLayer?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public deleteContromisuraTrasversale(codice?: string, codiceLayer?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public deleteContromisuraTrasversale(codice?: string, codiceLayer?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public deleteContromisuraTrasversale(codice?: string, codiceLayer?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (codiceLayer !== undefined && codiceLayer !== null) {
			queryParameters = queryParameters.set('codiceLayer', <any>codiceLayer);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/contromisureTrasversali`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la GET delle contromisure
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice Imposta filtro su campo codice
	 * @param idLibreria Imposta filtro su campo idLibreria
	 * @param nome Imposta filtro su campo nome
	 * @param nomeClasse Imposta filtro su campo nomeClasse
	 * @param layer Imposta filtro su campo layer
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllContromisureLibreria(page?: number, size?: number, codice?: string, idLibreria?: number, nome?: string, nomeClasse?: string, layer?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableRequisito>;
	public getAllContromisureLibreria(page?: number, size?: number, codice?: string, idLibreria?: number, nome?: string, nomeClasse?: string, layer?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableRequisito>>;
	public getAllContromisureLibreria(page?: number, size?: number, codice?: string, idLibreria?: number, nome?: string, nomeClasse?: string, layer?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableRequisito>>;
	public getAllContromisureLibreria(page?: number, size?: number, codice?: string, idLibreria?: number, nome?: string, nomeClasse?: string, layer?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (idLibreria !== undefined && idLibreria !== null) {
			queryParameters = queryParameters.set('idLibreria', <any>idLibreria);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (nomeClasse !== undefined && nomeClasse !== null) {
			queryParameters = queryParameters.set('nomeClasse', <any>nomeClasse);
		}
		if (layer !== undefined && layer !== null) {
			queryParameters = queryParameters.set('layer', <any>layer);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableRequisito>('get', `${this.basePath}/api/v1/libreriaContromisure`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la GET delle contromisure trasversali
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice Imposta filtro su campo codice
	 * @param codiceLayer Imposta filtro su campo codiceLayer
	 * @param gradoApplicabilita Imposta filtro su campo gradoApplicabilita
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllContromisureTrasversali(page?: number, size?: number, codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableRequisitoTrasversale>;
	public getAllContromisureTrasversali(page?: number, size?: number, codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableRequisitoTrasversale>>;
	public getAllContromisureTrasversali(page?: number, size?: number, codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableRequisitoTrasversale>>;
	public getAllContromisureTrasversali(page?: number, size?: number, codice?: string, codiceLayer?: string, gradoApplicabilita?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (codiceLayer !== undefined && codiceLayer !== null) {
			queryParameters = queryParameters.set('codiceLayer', <any>codiceLayer);
		}
		if (gradoApplicabilita !== undefined && gradoApplicabilita !== null) {
			queryParameters = queryParameters.set('gradoApplicabilita', <any>gradoApplicabilita);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableRequisitoTrasversale>('get', `${this.basePath}/api/v1/contromisureTrasversali`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
