/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {LinkRequisitoAppAnalisiChecklistDTO} from './linkRequisitoAppAnalisiChecklistDTO';
import {LinkRequisitoAssetAnalisiChecklistDTO} from './linkRequisitoAssetAnalisiChecklistDTO';
import {LinkRequisitoProcessoAnalisiChecklistDTO} from './linkRequisitoProcessoAnalisiChecklistDTO';
import {StoricoModifiche} from './storicoModifiche';

export interface EditChecklistDTO {
	id?: number;
	nome?: string;
	descrizione?: string;
	codiceAnalisi?: string;
	stato?: EditChecklistDTO.StatoEnum;
	requisitiTotali?: number;
	requisitiConRisposta?: number;
	createdAt?: Date;
	modifiedAt?: Date;
	expiresAt?: Date;
	ownerFullName?: string;
	ownerId?: number;
	storicoModifiche?: StoricoModifiche;
	setRequisitoProcessoChecklist?: Array<LinkRequisitoProcessoAnalisiChecklistDTO>;
	setRequisitoAssetChecklist?: Array<LinkRequisitoAssetAnalisiChecklistDTO>;
	setRequisitoAppChecklist?: Array<LinkRequisitoAppAnalisiChecklistDTO>;
	percentualeCompletamento?: number;
}

export namespace EditChecklistDTO {
	export type StatoEnum = 'IN_COMPILAZIONE' | 'INVIATA' | 'IN_CARICO_OWNER' | 'COMPLETATA' | 'CHIUSA' | 'RIFIUTATA';
	export const StatoEnum = {
		INCOMPILAZIONE: 'IN_COMPILAZIONE' as StatoEnum,
		INVIATA: 'INVIATA' as StatoEnum,
		INCARICOOWNER: 'IN_CARICO_OWNER' as StatoEnum,
		COMPLETATA: 'COMPLETATA' as StatoEnum,
		CHIUSA: 'CHIUSA' as StatoEnum,
		RIFIUTATA: 'RIFIUTATA' as StatoEnum
	};
}