import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class Globals {

	public TABLE_PROCESSES: number = 1;
	public TABLE_APPLICATIONS: number = 2;
	public TABLE_ASSETS: number = 3;
	public HTTP_NOT_ERROR_STATUS_ID: number = 0;
	public HTTP_ERROR_STATUS_ID: number = 1;
	public COMPANY_STATUS_ACTIVE: number = 1;
	public COMPANY_STATUS_NOT_ACTIVE: number = 0;
	public COMPANY_STATUS_BLOCKED: number = -1;
	public COMPANY_STATUS_CANCELLED: number = -2;
	public USER_STATUS_ACTIVE: number = 1;
	public USER_STATUS_NOT_ACTIVE: number = 0;
	public USER_STATUS_BLOCKED: number = -1;
	public USER_STATUS_CANCELLED: number = -2;
	public MAX_ELEMENT_AUTOCOMPLETE: number = 5;
	public IN_CREAZIONE = 'IN_CREAZIONE';
	public ASSOCIA_PROCESSI_ANALISI = 'ASSOCIA_PROCESSI_ANALISI';
	public ASSOCIA_AP_ANALISI = 'ASSOCIA_AP_ANALISI';
	public ASSOCIA_AP_ASSETS_ANALISI = 'ASSOCIA_AP_ASSETS_ANALISI';
	public ASSOCIA_PROCESSI_AP_ANALISI = 'ASSOCIA_PROCESSI_AP_ANALISI';
	public ASSOCIA_BIA_ANALISI = 'ASSOCIA_BIA_ANALISI';
	public ASSOCIA_INFO_ANALISI = 'ASSOCIA_INFO_ANALISI';
	public PERIMETRO_ANALISI_CONFERMATO = 'PERIMETRO_ANALISI_CONFERMATO';
	public ASSOCIA_PROCESSI_INFO_ANALISI = 'ASSOCIA_PROCESSI_INFO_ANALISI';
	public ASSOCIA_AP_INFO_ANALISI = 'ASSOCIA_AP_INFO_ANALISI';
	public ASSOCIA_ASSET_INFO_ANALISI = 'ASSOCIA_ASSET_INFO_ANALISI';
	public ASSOCIA_PROCESSI_PERIMETRI_ANALISI = 'ASSOCIA_PROCESSI_PERIMETRI_ANALISI';
	public ASSOCIA_AP_PERIMETRI_ANALISI = 'ASSOCIA_AP_PERIMETRI_ANALISI';
	public ASSOCIA_ASSET_PERIMETRI_ANALISI = 'ASSOCIA_ASSET_PERIMETRI_ANALISI';
	public EA_CONFERMATA = 'EA_CONFERMATA';
	public OVERRIDE_MINACCE_ANALISI = 'OVERRIDE_MINACCE_ANALISI';
	public OVERRIDE_VULNERABILITA_ANALISI = 'OVERRIDE_VULNERABILITA_ANALISI';
	public OVERRIDE_PROBABILITA_MINACCIA_TO_ANALISI = 'OVERRIDE_PROBABILITA_MINACCIA_TO_ANALISI';
	public CALCOLA_RISCHIO_POTENZIALE = 'CALCOLA_RISCHIO_POTENZIALE';
	public CALCOLA_CONTROMISURE = 'CALCOLA_CONTROMISURE';
	public CONTROMISURE_CALCOLATE = 'CONTROMISURE_CALCOLATE';
	public OVERRIDE_APPLICABILITA_ANALISI = 'OVERRIDE_APPLICABILITA_ANALISI';
	public CALCOLA_RISCHIO_EFFETTIVO = 'CALCOLA_RISCHIO_EFFETTIVO';
	public ANALISI_ELIMINATA = 'ANALISI_ELIMINATA';
	public CALCOLA_RISCHIO_POTENZIALE_IN_CORSO = 'CALCOLA_RISCHIO_POTENZIALE_IN_CORSO';
	public RISCHIO_POTENZIALE_CALCOLATO= 'RISCHIO_POTENZIALE_CALCOLATO';
	public CALCOLA_RISCHIO_EFFETTIVO_IN_CORSO= 'CALCOLA_RISCHIO_EFFETTIVO_IN_CORSO';
	public RISCHIO_EFFETTIVO_CALCOLATO = 'RISCHIO_EFFETTIVO_CALCOLATO';
	public CALCOLO_REQUISITI_PER_SIMULAZIONE_IN_CORSO = 'CALCOLO_REQUISITI_PER_SIMULAZIONE_IN_CORSO';
	public REQUISITI_PER_SIMULAZIONE_GENERATI = 'REQUISITI_PER_SIMULAZIONE_GENERATI';
	public CALCOLO_RISCHIO_RESIDUO_IN_CORSO= 'CALCOLO_RISCHIO_RESIDUO_IN_CORSO';
	public RISCHIO_RESIDUO_CALCOLATO= 'RISCHIO_RESIDUO_CALCOLATO';
	public COMPLETATA= 'COMPLETATA';

	public STATUS_IDS = new Map<string, number>([
		[this.IN_CREAZIONE, 1],
		[this.ASSOCIA_PROCESSI_ANALISI, 2],
		[this.ASSOCIA_AP_ANALISI, 3],
		[this.ASSOCIA_AP_ASSETS_ANALISI, 4],
		[this.ASSOCIA_PROCESSI_AP_ANALISI, 5],
		[this.ASSOCIA_BIA_ANALISI, 6],
		[this.ASSOCIA_INFO_ANALISI, 7],
		[this.PERIMETRO_ANALISI_CONFERMATO, 8],
		[this.ASSOCIA_AP_INFO_ANALISI, 9],
		[this.ASSOCIA_PROCESSI_INFO_ANALISI, 10],
		[this.ASSOCIA_ASSET_INFO_ANALISI, 11],
		[this.ASSOCIA_PROCESSI_PERIMETRI_ANALISI, 12],
		[this.ASSOCIA_AP_PERIMETRI_ANALISI, 13],
		[this.ASSOCIA_ASSET_PERIMETRI_ANALISI, 14],
		[this.EA_CONFERMATA, 15],
		[this.OVERRIDE_MINACCE_ANALISI, 16],
		[this.OVERRIDE_VULNERABILITA_ANALISI, 17],
		[this.OVERRIDE_PROBABILITA_MINACCIA_TO_ANALISI, 18],
		[this.CALCOLA_RISCHIO_POTENZIALE_IN_CORSO, 19],
		[this.RISCHIO_POTENZIALE_CALCOLATO, 20],
		[this.CALCOLA_CONTROMISURE, 21],
		[this.CONTROMISURE_CALCOLATE, 22],
		[this.OVERRIDE_APPLICABILITA_ANALISI, 23],
		[this.CALCOLA_RISCHIO_EFFETTIVO_IN_CORSO, 24],
		[this.RISCHIO_EFFETTIVO_CALCOLATO, 25],
		[this.CALCOLO_REQUISITI_PER_SIMULAZIONE_IN_CORSO, 26],
		[this.REQUISITI_PER_SIMULAZIONE_GENERATI, 27],
		[this.CALCOLO_RISCHIO_RESIDUO_IN_CORSO, 28],
		[this.RISCHIO_RESIDUO_CALCOLATO, 29],
		[this.COMPLETATA, 30],
		[this.ANALISI_ELIMINATA, 2147483647],
	]);
	// ID ruoli su Keymaker
	public ROLE_UNDEFINED_ID = 42;
	public ROLE_ROOT_ID = 37;
	public ROLE_PROJECT_MANAGER_ID = 38;
	public ROLE_SECURITY_MANAGER_ID = 39;
	public ROLE_PLATFORM_MANAGER_ID = 40;
	public ROLE_COVERAGE_OWNER_ID = 43;
	public ROLE_UNDEFINED: string = 'UNDEFINED';
	public ROLE_ROOT: string = 'ROOT';
	public ROLE_PROJECT_MANAGER: string = 'ROLE_PROJECT_MANAGER';
	public ROLE_SECURITY_MANAGER: string = 'ROLE_SECURITY_MANAGER';
	public ROLE_PLATFORM_MANAGER: string = 'ROLE_PLATFORM_MANAGER';
	public ROLE_COVERAGE_OWNER: string = 'ROLE_COVERAGE_OWNER';
	public ROLE_IDS = new Map<string, number>([
		[this.ROLE_ROOT, this.ROLE_ROOT_ID],
		[this.ROLE_UNDEFINED, this.ROLE_UNDEFINED_ID],
		[this.ROLE_PLATFORM_MANAGER, this.ROLE_PLATFORM_MANAGER_ID],
		[this.ROLE_PROJECT_MANAGER, this.ROLE_PROJECT_MANAGER_ID],
		[this.ROLE_SECURITY_MANAGER, this.ROLE_SECURITY_MANAGER_ID],
		[this.ROLE_COVERAGE_OWNER, this.ROLE_COVERAGE_OWNER_ID],
	]);
	public ROLE_IDS_KEYS = new Map<number, string>([
		[this.ROLE_UNDEFINED_ID, this.ROLE_UNDEFINED],
		[this.ROLE_PLATFORM_MANAGER_ID, this.ROLE_PLATFORM_MANAGER],
		[this.ROLE_PROJECT_MANAGER_ID, this.ROLE_PROJECT_MANAGER],
		[this.ROLE_SECURITY_MANAGER_ID, this.ROLE_SECURITY_MANAGER],
		[this.ROLE_COVERAGE_OWNER_ID, this.ROLE_COVERAGE_OWNER],
	]);
	public ROLES = [
		{value: this.ROLE_UNDEFINED_ID, title: 'Non definito', key: this.ROLE_UNDEFINED},
		{value: this.ROLE_PLATFORM_MANAGER_ID, title: 'Amministratore della Piattaforma', key: this.ROLE_PLATFORM_MANAGER},
		{value: this.ROLE_PROJECT_MANAGER_ID, title: 'Project Manager', key: this.ROLE_PROJECT_MANAGER},
		{value: this.ROLE_SECURITY_MANAGER_ID, title: 'Responsabile della Sicurezza', key: this.ROLE_SECURITY_MANAGER},
		{value: this.ROLE_COVERAGE_OWNER_ID, title: 'Responsabile Gestione Coperture', key: this.ROLE_COVERAGE_OWNER},
	];
	public KEYS_RICHIESTE: string[] = [
		"IN_ATTESA_NOTIFICA",
		"INVIATA_AD_OWNER",
		"RICHIESTA_CANCELLATA",
		"RICHIESTA_SCADUTA",
		"COMPLETATA",
	];

	getStatusId(s:string): number {
		return this.STATUS_IDS.get(s);
	}

	constructor(private translate: TranslateService) {

	}
}
