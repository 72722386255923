/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {SuccessfulResponseIterableInformazione} from '../model/successfulResponseIterableInformazione';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class InformazioneControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua il get delle informazioni ottenuta dal tool Bia
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param nome Imposta filtro su campo nome
	 * @param idBia Imposta filtro su campo idBia
	 * @param descrizione Imposta filtro su campo descrizione
	 * @param RR Imposta filtro su campo RR
	 * @param RI Imposta filtro su campo RI
	 * @param RD Imposta filtro su campo RD
	 * @param RTO Imposta filtro su campo RTO
	 * @param RPO Imposta filtro su campo RPO
	 * @param classificazione Imposta filtro su campo classificazione
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllInformazioni(page?: number, size?: number, nome?: string, idBia?: number, descrizione?: string, RR?: number, RI?: number, RD?: number, RTO?: number, RPO?: number, classificazione?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableInformazione>;
	public getAllInformazioni(page?: number, size?: number, nome?: string, idBia?: number, descrizione?: string, RR?: number, RI?: number, RD?: number, RTO?: number, RPO?: number, classificazione?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableInformazione>>;
	public getAllInformazioni(page?: number, size?: number, nome?: string, idBia?: number, descrizione?: string, RR?: number, RI?: number, RD?: number, RTO?: number, RPO?: number, classificazione?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableInformazione>>;
	public getAllInformazioni(page?: number, size?: number, nome?: string, idBia?: number, descrizione?: string, RR?: number, RI?: number, RD?: number, RTO?: number, RPO?: number, classificazione?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (idBia !== undefined && idBia !== null) {
			queryParameters = queryParameters.set('idBia', <any>idBia);
		}
		if (descrizione !== undefined && descrizione !== null) {
			queryParameters = queryParameters.set('descrizione', <any>descrizione);
		}
		if (RR !== undefined && RR !== null) {
			queryParameters = queryParameters.set('RR', <any>RR);
		}
		if (RI !== undefined && RI !== null) {
			queryParameters = queryParameters.set('RI', <any>RI);
		}
		if (RD !== undefined && RD !== null) {
			queryParameters = queryParameters.set('RD', <any>RD);
		}
		if (RTO !== undefined && RTO !== null) {
			queryParameters = queryParameters.set('RTO', <any>RTO);
		}
		if (RPO !== undefined && RPO !== null) {
			queryParameters = queryParameters.set('RPO', <any>RPO);
		}
		if (classificazione !== undefined && classificazione !== null) {
			queryParameters = queryParameters.set('classificazione', <any>classificazione);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableInformazione>('get', `${this.basePath}/api/v1/informazione`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
