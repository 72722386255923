/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {Analisi} from '../model/analisi';
import {AnalisiDTO} from '../model/analisiDTO';
import {Applicazione} from '../model/applicazione';
import {ApplicazioneKey} from '../model/applicazioneKey';
import {AssociaRequisitiRichiesteDTO} from '../model/associaRequisitiRichiesteDTO';
import {AssociateAppAssetAnalisiDTO} from '../model/associateAppAssetAnalisiDTO';
import {AssociateAppInformazioniAnalisiDTO} from '../model/associateAppInformazioniAnalisiDTO';
import {AssociateAppPerimetriAnalisiDTO} from '../model/associateAppPerimetriAnalisiDTO';
import {AssociateAppProcessoAnalisiDTO} from '../model/associateAppProcessoAnalisiDTO';
import {AssociateAssetInformazioniAnalisiDTO} from '../model/associateAssetInformazioniAnalisiDTO';
import {AssociateAssetPerimetriAnalisiDTO} from '../model/associateAssetPerimetriAnalisiDTO';
import {AssociateProcessoInformazioniAnalisiDTO} from '../model/associateProcessoInformazioniAnalisiDTO';
import {AssociateProcessoPerimetriAnalisiDTO} from '../model/associateProcessoPerimetriAnalisiDTO';
import {Bia} from '../model/bia';
import {OverrideApplicabilitaRequisitiToAnalisiDTO} from '../model/overrideApplicabilitaRequisitiToAnalisiDTO';
import {OverrideMinacceToAnalisiDTO} from '../model/overrideMinacceToAnalisiDTO';
import {OverrideProbabilitaMinacciaToAnalisiDTO} from '../model/overrideProbabilitaMinacciaToAnalisiDTO';
import {OverrideVulnerabilitaToAnalisiDTO} from '../model/overrideVulnerabilitaToAnalisiDTO';
import {Processo} from '../model/processo';
import {SuccessfulResponseAnalisi} from '../model/successfulResponseAnalisi';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseGetRequisitiDiComlianceDTO} from '../model/successfulResponseGetRequisitiDiComlianceDTO';
import {SuccessfulResponseInteger} from '../model/successfulResponseInteger';
import {SuccessfulResponseIterableInformazione} from '../model/successfulResponseIterableInformazione';
import {SuccessfulResponseMapStringInteger} from '../model/successfulResponseMapStringInteger';
import {
	SuccessfulResponseSimulazioneRequisitiDiComplianceDTO
} from '../model/successfulResponseSimulazioneRequisitiDiComplianceDTO';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class AnalisiControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua l&#x27;assegnazione di richieste di copertura requisiti ai rispettivi owners per l&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle associazioni
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associaRequisitiRichieste(body: AssociaRequisitiRichiesteDTO, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associaRequisitiRichieste(body: AssociaRequisitiRichiesteDTO, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associaRequisitiRichieste(body: AssociaRequisitiRichiesteDTO, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associaRequisitiRichieste(body: AssociaRequisitiRichiesteDTO, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associaRequisitiRichieste.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associaRequisitiRichieste.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associaRequisitiRichieste`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Applicazioni ed informazioni all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle App ed informazioni da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateAppInformazioniToAnalisi(body: Array<AssociateAppInformazioniAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateAppInformazioniToAnalisi(body: Array<AssociateAppInformazioniAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateAppInformazioniToAnalisi(body: Array<AssociateAppInformazioniAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateAppInformazioniToAnalisi(body: Array<AssociateAppInformazioniAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateAppInformazioniToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateAppInformazioniToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioniInformazioni`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Applicazioni ed Asset all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle applicazioni ed assets da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateApplicazioniAssetToAnalisi(body: Array<AssociateAppAssetAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateApplicazioniAssetToAnalisi(body: Array<AssociateAppAssetAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateApplicazioniAssetToAnalisi(body: Array<AssociateAppAssetAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateApplicazioniAssetToAnalisi(body: Array<AssociateAppAssetAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateApplicazioniAssetToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateApplicazioniAssetToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioniAssets`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Applicazioni e Processi all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle applicazioni ed dei processi da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateApplicazioniProcessiToAnalisi(body: Array<AssociateAppProcessoAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateApplicazioniProcessiToAnalisi(body: Array<AssociateAppProcessoAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateApplicazioniProcessiToAnalisi(body: Array<AssociateAppProcessoAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateApplicazioniProcessiToAnalisi(body: Array<AssociateAppProcessoAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateApplicazioniProcessiToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateApplicazioniProcessiToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioniProcessi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Applicazioni all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle applicazioni da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateApplicazioniToAnalisi(body: Array<ApplicazioneKey>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateApplicazioniToAnalisi(body: Array<ApplicazioneKey>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateApplicazioniToAnalisi(body: Array<ApplicazioneKey>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateApplicazioniToAnalisi(body: Array<ApplicazioneKey>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateApplicazioniToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateApplicazioniToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateApplicazioni`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di App e perimetri all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista degli Apps e perimetri da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateAppsPerimetriToAnalisi(body: Array<AssociateAppPerimetriAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateAppsPerimetriToAnalisi(body: Array<AssociateAppPerimetriAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateAppsPerimetriToAnalisi(body: Array<AssociateAppPerimetriAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateAppsPerimetriToAnalisi(body: Array<AssociateAppPerimetriAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateAppsPerimetriToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateAppsPerimetriToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateAppPerimetri`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Asset ed informazioni all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista degli Asset ed informazioni da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateAssetInformazioniToAnalisi(body: Array<AssociateAssetInformazioniAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateAssetInformazioniToAnalisi(body: Array<AssociateAssetInformazioniAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateAssetInformazioniToAnalisi(body: Array<AssociateAssetInformazioniAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateAssetInformazioniToAnalisi(body: Array<AssociateAssetInformazioniAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateAssetInformazioniToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateAssetInformazioniToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateAssetInformazioni`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Asset e perimetri all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista degli Asset e perimetri da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateAssetPerimetriToAnalisi(body: Array<AssociateAssetPerimetriAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateAssetPerimetriToAnalisi(body: Array<AssociateAssetPerimetriAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateAssetPerimetriToAnalisi(body: Array<AssociateAssetPerimetriAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateAssetPerimetriToAnalisi(body: Array<AssociateAssetPerimetriAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateAssetPerimetriToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateAssetPerimetriToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateAssetPerimetri`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione tra le informazioni trattate da una bia all&#x27;analisi con codice {codice}
	 *
	 * @param body Bia che si desidera associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateBiaToAnalisi(body: Bia, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateBiaToAnalisi(body: Bia, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateBiaToAnalisi(body: Bia, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateBiaToAnalisi(body: Bia, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateBiaToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateBiaToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateBia`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Informazioni all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle informazioni da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateInfoToAnalisi(body: Array<string>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateInfoToAnalisi(body: Array<string>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateInfoToAnalisi(body: Array<string>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateInfoToAnalisi(body: Array<string>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateInfoToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateInfoToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateInformazioni`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Pricessi ed informazioni all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista degli Processi ed informazioni da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateProcessiInformazioniToAnalisi(body: Array<AssociateProcessoInformazioniAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateProcessiInformazioniToAnalisi(body: Array<AssociateProcessoInformazioniAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateProcessiInformazioniToAnalisi(body: Array<AssociateProcessoInformazioniAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateProcessiInformazioniToAnalisi(body: Array<AssociateProcessoInformazioniAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateProcessiInformazioniToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateProcessiInformazioniToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateProcessiInformazioni`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Processi e perimetri all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista dei Processi e perimetri da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateProcessiPerimetriToAnalisi(body: Array<AssociateProcessoPerimetriAnalisiDTO>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateProcessiPerimetriToAnalisi(body: Array<AssociateProcessoPerimetriAnalisiDTO>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateProcessiPerimetriToAnalisi(body: Array<AssociateProcessoPerimetriAnalisiDTO>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateProcessiPerimetriToAnalisi(body: Array<AssociateProcessoPerimetriAnalisiDTO>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateProcessiPerimetriToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateProcessiPerimetriToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateProcessiPerimetri`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;associazione di un set di Processi all&#x27;analisi con codice {codice}
	 *
	 * @param body Lista dei processi da associare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public associateProcessiToAnalisi(body: Array<string>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public associateProcessiToAnalisi(body: Array<string>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public associateProcessiToAnalisi(body: Array<string>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public associateProcessiToAnalisi(body: Array<string>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling associateProcessiToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling associateProcessiToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/associateProcessi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il calcolo dei requisiti per l&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle fonti che si desidera abilitare
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public calcolaRequisiti(body: Array<string>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public calcolaRequisiti(body: Array<string>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public calcolaRequisiti(body: Array<string>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public calcolaRequisiti(body: Array<string>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling calcolaRequisiti.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling calcolaRequisiti.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/calcolaRequisiti`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il calcolo del rischio effettivo dell&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public calcolaRischioEffettivo(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public calcolaRischioEffettivo(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public calcolaRischioEffettivo(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public calcolaRischioEffettivo(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling calcolaRischioEffettivo.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/calcolaRischioEffettivo`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il calcolo del rischio potenziale dell&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public calcolaRischioPotenziale(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public calcolaRischioPotenziale(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public calcolaRischioPotenziale(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public calcolaRischioPotenziale(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling calcolaRischioPotenziale.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/calcolaRischioPotenziale`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la creazione di una nuova analisi
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public changeAnalisi(body: AnalisiDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseAnalisi>;
	public changeAnalisi(body: AnalisiDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseAnalisi>>;
	public changeAnalisi(body: AnalisiDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseAnalisi>>;
	public changeAnalisi(body: AnalisiDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling changeAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseAnalisi>('put', `${this.basePath}/api/v1/analisi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la chiusura di un&#x27;analisi
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public chiudiAnalisi(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public chiudiAnalisi(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public chiudiAnalisi(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public chiudiAnalisi(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling chiudiAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/chiudiAnalisi`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il congelamento del perimetro di analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public chiudiEA(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public chiudiEA(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public chiudiEA(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public chiudiEA(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling chiudiEA.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/chiudiEA`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il congelamento del perimetro di analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public chiudiPerimetroAnalisi(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public chiudiPerimetroAnalisi(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public chiudiPerimetroAnalisi(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public chiudiPerimetroAnalisi(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling chiudiPerimetroAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/chiudiPerimetroAnalisi`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la delezione di una nuova analisi
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteAnalisi(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public deleteAnalisi(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public deleteAnalisi(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public deleteAnalisi(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling deleteAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Simula il rischio residuo per l&#x27;analisi con {codice} passando una lista di codici requisiti
	 *
	 * @param codice
	 * @param codiceNuovaAnalisi
	 * @param isSimulazione
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public duplicaAnalisi(codice: string, codiceNuovaAnalisi: string, isSimulazione: boolean, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public duplicaAnalisi(codice: string, codiceNuovaAnalisi: string, isSimulazione: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public duplicaAnalisi(codice: string, codiceNuovaAnalisi: string, isSimulazione: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public duplicaAnalisi(codice: string, codiceNuovaAnalisi: string, isSimulazione: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling duplicaAnalisi.');
		}

		if (codiceNuovaAnalisi === null || codiceNuovaAnalisi === undefined) {
			throw new Error('Required parameter codiceNuovaAnalisi was null or undefined when calling duplicaAnalisi.');
		}

		if (isSimulazione === null || isSimulazione === undefined) {
			throw new Error('Required parameter isSimulazione was null or undefined when calling duplicaAnalisi.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (codiceNuovaAnalisi !== undefined && codiceNuovaAnalisi !== null) {
			queryParameters = queryParameters.set('codiceNuovaAnalisi', <any>codiceNuovaAnalisi);
		}
		if (isSimulazione !== undefined && isSimulazione !== null) {
			queryParameters = queryParameters.set('isSimulazione', <any>isSimulazione);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/duplica`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get di tutte le analisi in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice Imposta filtro su campo codice
	 * @param nome Imposta filtro su campo nome
	 * @param fromData Imposta filtro per le analisi a partire dalla data fromData
	 * @param toData Imposta filtro per le analisi fino alla data toData
	 * @param dominioDiAnalisi Imposta filtro su campo dominioDiAnalisi
	 * @param cliente Imposta filtro su campo cliente
	 * @param stato Imposta filtro su campo stato
	 * @param owner Imposta filtro su campo owner
	 * @param includiEliminate Impostare a true qualora si desiderasse includere le analisi eliminate
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, includiEliminate?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public getAllAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, includiEliminate?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public getAllAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, includiEliminate?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public getAllAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, includiEliminate?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (fromData !== undefined && fromData !== null) {
			queryParameters = queryParameters.set('fromData', <any>fromData.toISOString());
		}
		if (toData !== undefined && toData !== null) {
			queryParameters = queryParameters.set('toData', <any>toData.toISOString());
		}
		if (dominioDiAnalisi !== undefined && dominioDiAnalisi !== null) {
			queryParameters = queryParameters.set('dominioDiAnalisi', <any>dominioDiAnalisi);
		}
		if (cliente !== undefined && cliente !== null) {
			queryParameters = queryParameters.set('cliente', <any>cliente);
		}
		if (stato !== undefined && stato !== null) {
			queryParameters = queryParameters.set('stato', <any>stato);
		}
		if (owner !== undefined && owner !== null) {
			queryParameters = queryParameters.set('owner', <any>owner);
		}
		if (includiEliminate !== undefined && includiEliminate !== null) {
			queryParameters = queryParameters.set('includiEliminate', <any>includiEliminate);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<any>('get', `${this.basePath}/api/v1/analisi`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get di tutte le analisi in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice Imposta filtro su campo codice
	 * @param nome Imposta filtro su campo nome
	 * @param fromData Imposta filtro per le analisi a partire dalla data fromData
	 * @param toData Imposta filtro per le analisi fino alla data toData
	 * @param dominioDiAnalisi Imposta filtro su campo dominioDiAnalisi
	 * @param cliente Imposta filtro su campo cliente
	 * @param stato Imposta filtro su campo stato
	 * @param owner Imposta filtro su campo owner
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllStoricoAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public getAllStoricoAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public getAllStoricoAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public getAllStoricoAnalisi(page?: number, size?: number, codice?: string, nome?: string, fromData?: Date, toData?: Date, dominioDiAnalisi?: string, cliente?: string, stato?: string, owner?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (fromData !== undefined && fromData !== null) {
			queryParameters = queryParameters.set('fromData', <any>fromData.toISOString());
		}
		if (toData !== undefined && toData !== null) {
			queryParameters = queryParameters.set('toData', <any>toData.toISOString());
		}
		if (dominioDiAnalisi !== undefined && dominioDiAnalisi !== null) {
			queryParameters = queryParameters.set('dominioDiAnalisi', <any>dominioDiAnalisi);
		}
		if (cliente !== undefined && cliente !== null) {
			queryParameters = queryParameters.set('cliente', <any>cliente);
		}
		if (stato !== undefined && stato !== null) {
			queryParameters = queryParameters.set('stato', <any>stato);
		}
		if (owner !== undefined && owner !== null) {
			queryParameters = queryParameters.set('owner', <any>owner);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<any>('get', `${this.basePath}/api/v1/analisi/storico`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get dell&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAnalisiByCodice(codice: string, observe?: 'body', reportProgress?: boolean): Observable<Analisi>;
	public getAnalisiByCodice(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analisi>>;
	public getAnalisiByCodice(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analisi>>;
	public getAnalisiByCodice(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getAnalisiByCodice.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<Analisi>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get delle Applicazioni associate all&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getApplicazioniAssociateByCodiceAnalisi(codice: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Applicazione>>;
	public getApplicazioniAssociateByCodiceAnalisi(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Applicazione>>>;
	public getApplicazioniAssociateByCodiceAnalisi(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Applicazione>>>;
	public getApplicazioniAssociateByCodiceAnalisi(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getApplicazioniAssociateByCodiceAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<Array<Applicazione>>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/getApplicazioniAssociate`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get di tutte le informazioni associate all&#x27;analisi
	 *
	 * @param codiceAnalisi Imposta filtro su campo codice dell&#x27;analisi
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice Imposta filtro su campo codice dell&#x27;informazione
	 * @param nome Imposta filtro su campo nome dell&#x27;informazione
	 * @param descrizione Imposta filtro su campo descrizione dell&#x27;informazione
	 * @param classificazione Imposta filtro su campo classificazione dell&#x27;informazione
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getInformazioniAnalisi(codiceAnalisi: string, page?: number, size?: number, codice?: string, nome?: string, descrizione?: string, classificazione?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableInformazione>;
	public getInformazioniAnalisi(codiceAnalisi: string, page?: number, size?: number, codice?: string, nome?: string, descrizione?: string, classificazione?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableInformazione>>;
	public getInformazioniAnalisi(codiceAnalisi: string, page?: number, size?: number, codice?: string, nome?: string, descrizione?: string, classificazione?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableInformazione>>;
	public getInformazioniAnalisi(codiceAnalisi: string, page?: number, size?: number, codice?: string, nome?: string, descrizione?: string, classificazione?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codiceAnalisi === null || codiceAnalisi === undefined) {
			throw new Error('Required parameter codiceAnalisi was null or undefined when calling getInformazioniAnalisi.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (descrizione !== undefined && descrizione !== null) {
			queryParameters = queryParameters.set('descrizione', <any>descrizione);
		}
		if (classificazione !== undefined && classificazione !== null) {
			queryParameters = queryParameters.set('classificazione', <any>classificazione);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableInformazione>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codiceAnalisi))}/info`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get delle Applicazioni associate a ciascun processo nell&#x27;ambito dell&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getProcessiApplicazioniAssociate(codice: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: Array<ApplicazioneKey>; }>;
	public getProcessiApplicazioniAssociate(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: Array<ApplicazioneKey>; }>>;
	public getProcessiApplicazioniAssociate(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: Array<ApplicazioneKey>; }>>;
	public getProcessiApplicazioniAssociate(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getProcessiApplicazioniAssociate.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<{ [key: string]: Array<ApplicazioneKey>; }>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/getProcessiApplicazioniAssociate`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get dei Processi associati all&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getProcessiAssociatiByCodiceAnalisi(codice: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Processo>>;
	public getProcessiAssociatiByCodiceAnalisi(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Processo>>>;
	public getProcessiAssociatiByCodiceAnalisi(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Processo>>>;
	public getProcessiAssociatiByCodiceAnalisi(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getProcessiAssociatiByCodiceAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<Array<Processo>>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/getProcessiAssociati`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Restituisce tutti i requisiti di compliance per l&#x27;analisi con {codice} non implementati
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRequisitiDiCompliance(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseGetRequisitiDiComlianceDTO>;
	public getRequisitiDiCompliance(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseGetRequisitiDiComlianceDTO>>;
	public getRequisitiDiCompliance(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseGetRequisitiDiComlianceDTO>>;
	public getRequisitiDiCompliance(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getRequisitiDiCompliance.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseGetRequisitiDiComlianceDTO>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/requisiticompliance`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Restituisce tutti i requisiti de piano di trattamento per l&#x27;analisi con {codice} inclusi e/o esclusi dal calcolo
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRequisitiPiano(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseSimulazioneRequisitiDiComplianceDTO>;
	public getRequisitiPiano(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseSimulazioneRequisitiDiComplianceDTO>>;
	public getRequisitiPiano(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseSimulazioneRequisitiDiComplianceDTO>>;
	public getRequisitiPiano(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getRequisitiPiano.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseSimulazioneRequisitiDiComplianceDTO>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/requisitipiano`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Restituisce il rischio residuo per l&#x27;analisi con {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRischioResiduo(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseInteger>;
	public getRischioResiduo(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseInteger>>;
	public getRischioResiduo(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseInteger>>;
	public getRischioResiduo(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getRischioResiduo.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseInteger>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/rischioresiduo`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il retrieve della distribuzione degli stati delle analisi di cui l&#x27;utente è l&#x27;owner
	 *
	 * @param fromData Imposta filtro per le analisi a partire dalla data fromData
	 * @param toData Imposta filtro per le analisi fino alla data toData
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getStatisticheAnalisi(fromData?: Date, toData?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseMapStringInteger>;
	public getStatisticheAnalisi(fromData?: Date, toData?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseMapStringInteger>>;
	public getStatisticheAnalisi(fromData?: Date, toData?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseMapStringInteger>>;
	public getStatisticheAnalisi(fromData?: Date, toData?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (fromData !== undefined && fromData !== null) {
			queryParameters = queryParameters.set('fromData', <any>fromData.toISOString());
		}
		if (toData !== undefined && toData !== null) {
			queryParameters = queryParameters.set('toData', <any>toData.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseMapStringInteger>('get', `${this.basePath}/api/v1/analisi/statistiche`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get dell&#x27;analisi in storico con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getStoricoAnalisiByCodice(codice: string, observe?: 'body', reportProgress?: boolean): Observable<Analisi>;
	public getStoricoAnalisiByCodice(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analisi>>;
	public getStoricoAnalisiByCodice(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analisi>>;
	public getStoricoAnalisiByCodice(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling getStoricoAnalisiByCodice.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<Analisi>('get', `${this.basePath}/api/v1/analisi/storico/${encodeURIComponent(String(codice))}`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la creazione di una nuova analisi
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public newAnalisi(body: AnalisiDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseAnalisi>;
	public newAnalisi(body: AnalisiDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseAnalisi>>;
	public newAnalisi(body: AnalisiDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseAnalisi>>;
	public newAnalisi(body: AnalisiDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling newAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json;charset=UTF-8'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseAnalisi>('post', `${this.basePath}/api/v1/analisi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;override delle applicabilita dei requisiti ad Assets, Apps e Processi nell&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle applicabilita dei requisiti da associare ad app, processi ed assets
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public overrideApplicabilitaToAnalisi(body: OverrideApplicabilitaRequisitiToAnalisiDTO, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public overrideApplicabilitaToAnalisi(body: OverrideApplicabilitaRequisitiToAnalisiDTO, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public overrideApplicabilitaToAnalisi(body: OverrideApplicabilitaRequisitiToAnalisiDTO, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public overrideApplicabilitaToAnalisi(body: OverrideApplicabilitaRequisitiToAnalisiDTO, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling overrideApplicabilitaToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling overrideApplicabilitaToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/overrideApplicabilitaToAnalisi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;override delle minacce associate ad Assets, Apps e Processi nell&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle minacce da associare ad app, processi ed assets
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public overrideMinacceToAnalisi(body: OverrideMinacceToAnalisiDTO, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public overrideMinacceToAnalisi(body: OverrideMinacceToAnalisiDTO, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public overrideMinacceToAnalisi(body: OverrideMinacceToAnalisiDTO, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public overrideMinacceToAnalisi(body: OverrideMinacceToAnalisiDTO, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling overrideMinacceToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling overrideMinacceToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/overrideMinacceToAnalisi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;override della probabilita di minaccia associate ad Assets, Apps e Processi nell&#x27;analisi con codice {codice}
	 *
	 * @param body Lista degli ovverride minacce associate a Processi, app ed assets
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public overrideProbabilitaMinacciaToAnalisi(body: OverrideProbabilitaMinacciaToAnalisiDTO, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public overrideProbabilitaMinacciaToAnalisi(body: OverrideProbabilitaMinacciaToAnalisiDTO, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public overrideProbabilitaMinacciaToAnalisi(body: OverrideProbabilitaMinacciaToAnalisiDTO, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public overrideProbabilitaMinacciaToAnalisi(body: OverrideProbabilitaMinacciaToAnalisiDTO, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling overrideProbabilitaMinacciaToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling overrideProbabilitaMinacciaToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/overrideProbabilitaMinacciaToAnalisi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;override delle vulnerabilita associate ad Assets, Apps e Processi nell&#x27;analisi con codice {codice}
	 *
	 * @param body Lista delle vulnerabilità associate a Processi, app ed assets di cui si vuole fare l&#x27;override
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public overrideProcessiVulnerabilitaToAnalisi(body: OverrideVulnerabilitaToAnalisiDTO, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public overrideProcessiVulnerabilitaToAnalisi(body: OverrideVulnerabilitaToAnalisiDTO, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public overrideProcessiVulnerabilitaToAnalisi(body: OverrideVulnerabilitaToAnalisiDTO, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public overrideProcessiVulnerabilitaToAnalisi(body: OverrideVulnerabilitaToAnalisiDTO, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling overrideProcessiVulnerabilitaToAnalisi.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling overrideProcessiVulnerabilitaToAnalisi.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/overrideVulnerabilitaToAnalisi`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Simula il rischio residuo per l&#x27;analisi con {codice}
	 *
	 * @param codice
	 * @param sogliaRischioMassimo
	 * @param sogliaPesoDiContribuzione
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public simulaRischioResiduo(codice: string, sogliaRischioMassimo: number, sogliaPesoDiContribuzione: number, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public simulaRischioResiduo(codice: string, sogliaRischioMassimo: number, sogliaPesoDiContribuzione: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public simulaRischioResiduo(codice: string, sogliaRischioMassimo: number, sogliaPesoDiContribuzione: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public simulaRischioResiduo(codice: string, sogliaRischioMassimo: number, sogliaPesoDiContribuzione: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling simulaRischioResiduo.');
		}

		if (sogliaRischioMassimo === null || sogliaRischioMassimo === undefined) {
			throw new Error('Required parameter sogliaRischioMassimo was null or undefined when calling simulaRischioResiduo.');
		}

		if (sogliaPesoDiContribuzione === null || sogliaPesoDiContribuzione === undefined) {
			throw new Error('Required parameter sogliaPesoDiContribuzione was null or undefined when calling simulaRischioResiduo.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (sogliaRischioMassimo !== undefined && sogliaRischioMassimo !== null) {
			queryParameters = queryParameters.set('sogliaRischioMassimo', <any>sogliaRischioMassimo);
		}
		if (sogliaPesoDiContribuzione !== undefined && sogliaPesoDiContribuzione !== null) {
			queryParameters = queryParameters.set('sogliaPesoDiContribuzione', <any>sogliaPesoDiContribuzione);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('get', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/simularischioresiduo`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Simula il rischio residuo per l&#x27;analisi con {codice} passando una lista di codici requisiti
	 *
	 * @param body
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public simulaRischioResiduoConRequisiti(body: Array<string>, codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public simulaRischioResiduoConRequisiti(body: Array<string>, codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public simulaRischioResiduoConRequisiti(body: Array<string>, codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public simulaRischioResiduoConRequisiti(body: Array<string>, codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling simulaRischioResiduoConRequisiti.');
		}

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling simulaRischioResiduoConRequisiti.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/simularischioresiduorequisiti`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * x
	 *
	 * @param email Indirizzo email di test
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public testEmail(email?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public testEmail(email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public testEmail(email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public testEmail(email?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (email !== undefined && email !== null) {
			queryParameters = queryParameters.set('email', <any>email);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('get', `${this.basePath}/api/v1/testemail`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua la valorizzazione ad IMPLEMENTATO delle contromisure trasversali per l&#x27;analisi con codice {codice}
	 *
	 * @param codice
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public valorizzaContromisureTrasversali(codice: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public valorizzaContromisureTrasversali(codice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public valorizzaContromisureTrasversali(codice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public valorizzaContromisureTrasversali(codice: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (codice === null || codice === undefined) {
			throw new Error('Required parameter codice was null or undefined when calling valorizzaContromisureTrasversali.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/analisi/${encodeURIComponent(String(codice))}/valorizzaContromisureTrasversali`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
