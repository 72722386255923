/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseIterableScheduledTask} from '../model/successfulResponseIterableScheduledTask';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class ScheduledTasksControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Cambia lo stato del task desiderato
	 *
	 * @param id Imposta filtro su campo id
	 * @param nuovoStato Imposta filtro su campo nuovoStato
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public changeStatusScheduledTask(id?: number, nuovoStato?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public changeStatusScheduledTask(id?: number, nuovoStato?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public changeStatusScheduledTask(id?: number, nuovoStato?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public changeStatusScheduledTask(id?: number, nuovoStato?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (id !== undefined && id !== null) {
			queryParameters = queryParameters.set('id', <any>id);
		}
		if (nuovoStato !== undefined && nuovoStato !== null) {
			queryParameters = queryParameters.set('nuovoStato', <any>nuovoStato);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('put', `${this.basePath}/api/v1/scheduledTasks`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Rimuove il task desiderato
	 *
	 * @param id Imposta filtro su campo id
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteScheduledTask(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public deleteScheduledTask(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public deleteScheduledTask(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public deleteScheduledTask(id?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (id !== undefined && id !== null) {
			queryParameters = queryParameters.set('id', <any>id);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/scheduledTasks`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della code dei tasks
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param id Imposta filtro su campo id
	 * @param stati Imposta filtro su campo stati
	 * @param tipi Imposta filtro su campo tipi
	 * @param fromCreatedAt Imposta filtro per le analisi a partire dalla data fromCreatedAt
	 * @param toCreatedAt Imposta filtro per le analisi a partire dalla data toCreatedAt
	 * @param fromUpdatedAt Imposta filtro per le analisi a partire dalla data fromUpdatedAt
	 * @param toUpdatedAt Imposta filtro per le analisi a partire dalla data toUpdatedAt
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllScheduledTasks(page?: number, size?: number, id?: number, stati?: Array<string>, tipi?: Array<string>, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableScheduledTask>;
	public getAllScheduledTasks(page?: number, size?: number, id?: number, stati?: Array<string>, tipi?: Array<string>, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableScheduledTask>>;
	public getAllScheduledTasks(page?: number, size?: number, id?: number, stati?: Array<string>, tipi?: Array<string>, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableScheduledTask>>;
	public getAllScheduledTasks(page?: number, size?: number, id?: number, stati?: Array<string>, tipi?: Array<string>, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (id !== undefined && id !== null) {
			queryParameters = queryParameters.set('id', <any>id);
		}
		if (stati) {
			stati.forEach((element) => {
				queryParameters = queryParameters.append('stati', <any>element);
			})
		}
		if (tipi) {
			tipi.forEach((element) => {
				queryParameters = queryParameters.append('tipi', <any>element);
			})
		}
		if (fromCreatedAt !== undefined && fromCreatedAt !== null) {
			queryParameters = queryParameters.set('fromCreatedAt', <any>fromCreatedAt.toISOString());
		}
		if (toCreatedAt !== undefined && toCreatedAt !== null) {
			queryParameters = queryParameters.set('toCreatedAt', <any>toCreatedAt.toISOString());
		}
		if (fromUpdatedAt !== undefined && fromUpdatedAt !== null) {
			queryParameters = queryParameters.set('fromUpdatedAt', <any>fromUpdatedAt.toISOString());
		}
		if (toUpdatedAt !== undefined && toUpdatedAt !== null) {
			queryParameters = queryParameters.set('toUpdatedAt', <any>toUpdatedAt.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableScheduledTask>('get', `${this.basePath}/api/v1/scheduledTasks`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
