export const environment = {
	production: false,
	refreshPercentage: 0.5,
	tableItemPerPage: 10,
	tableDashboardModalItemPerPage: 5,
	tableEnablePager: true,
	tableDisablePager: false,
	authHeader: 'X-Auth-Token',
	defaultLang: 'it',
	dateFormat: 'dd/MM/yyyy HH:mm:ss',
	simpleDateFormat: 'dd/MM/yyyy',
	changePasswordInterval: 7776000, // 3 mesi
	REFRESH_TIME_RICHIESTE: 30000,

	// SSO CONFIG
	domain: '.' + window.location.hostname.split('.').slice(-2).join('.'),
	appName: 'adrappws',
	authCookie: '_keymaker_token',
	appCookie: '_keymaker_app',
	roleCookie: '_keymaker_roles',
	apiBaseEndpoint: 'https://adr.test-securside.it/api/',

	// SSO SERVER DEV CONFIG
	authEndpoint: 'keymakerauthws/auth/',
	companyEndpoint: 'keymakeruserws/company/',
	companyLevelEndpoint: 'keymakeruserws/companylevel/',
	userEndpoint: 'keymakeruserws/user/',
	roleEndpoint: 'keymakeruserws/role/',
	refreshTokenEndpoint: 'keymakerauthws/auth/refresh',
	userImportEndpoint: 'keymakeruserws/user_import/',
	fileUserEndpoint: 'keymakeruserws/file/',
	privilegeEndpoint:  'adrappws/privilege/',
	applicationEndpoint: 'adrappws/api/v1/applicazione/',
	processesEndpoint: 'adrappws/api/v1/processo/',
	threatEndpoint: 'adrappws/api/v1/minaccia/',
	assetEndpoint: 'adrappws/api/v1/asset/',
	analysisEndpoint: 'adrappws/api/v1/analisi/',
	excelEndpoint: 'adrappws/api/v1/excel/',
	biaEndpoint: 'adrappws/api/v1/bia/',
	layerEndpoint: 'adrappws/api/v1/analisi/',
	syncEndpoint: 'adrappws/sync/',
	dashboardEndpoint: 'adrappws/dashboard/',
	requestEndpoint: 'adrappws/api/v1/richiesta/',
	checklistEndpoint: 'adrappws/api/v1/checklist/',

};
