/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {LinkRequisitoAppAnalisiChecklist} from './linkRequisitoAppAnalisiChecklist';
import {LinkRequisitoAssetAnalisiChecklist} from './linkRequisitoAssetAnalisiChecklist';
import {LinkRequisitoProcessoAnalisiChecklist} from './linkRequisitoProcessoAnalisiChecklist';
import {StoricoModifiche} from './storicoModifiche';

export interface Checklist {
	id?: number;
	nome?: string;
	descrizione?: string;
	codiceAnalisi?: string;
	stato?: Checklist.StatoEnum;
	requisitiTotali?: number;
	requisitiConRisposta?: number;
	createdAt?: Date;
	modifiedAt?: Date;
	expiresAt?: Date;
	storicoModifiche?: StoricoModifiche;
	setRequisitoProcessoChecklist?: Array<LinkRequisitoProcessoAnalisiChecklist>;
	setRequisitoAssetChecklist?: Array<LinkRequisitoAssetAnalisiChecklist>;
	setRequisitoAppChecklist?: Array<LinkRequisitoAppAnalisiChecklist>;
	ownerFullName?: string;
	ownerId?: number;
	percentualeCompletamento?: number;
}

export namespace Checklist {
	export type StatoEnum = 'IN_COMPILAZIONE' | 'INVIATA' | 'IN_CARICO_OWNER' | 'COMPLETATA' | 'CHIUSA' | 'RIFIUTATA';
	export const StatoEnum = {
		INCOMPILAZIONE: 'IN_COMPILAZIONE' as StatoEnum,
		INVIATA: 'INVIATA' as StatoEnum,
		INCARICOOWNER: 'IN_CARICO_OWNER' as StatoEnum,
		COMPLETATA: 'COMPLETATA' as StatoEnum,
		CHIUSA: 'CHIUSA' as StatoEnum,
		RIFIUTATA: 'RIFIUTATA' as StatoEnum
	};
}