import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {LayoutService, StateService} from './utils';
import {NbPasswordAuthStrategy} from '../auth/strategies/password/password-strategy';
import {environment} from '../../environments/environment';
import {NbAuthJWTToken} from '../auth/services/token/token';
import {HttpResponse} from '@angular/common/http';
import {NbAuthModule} from '../auth/auth.module';
import {NbRoleProvider} from '../security/services/role.provider';
import {UserApiService} from './data/user-api.service';
import {ApiService} from './data/api.service';
import {APIS} from './apis';

const socialLinks = [
	{
		url: 'https://github.com/akveo/nebular',
		target: '_blank',
		icon: 'github',
	},
	{
		url: 'https://www.facebook.com/akveo/',
		target: '_blank',
		icon: 'facebook',
	},
	{
		url: 'https://twitter.com/akveo_inc',
		target: '_blank',
		icon: 'twitter',
	},
];

export class NbSimpleRoleProvider extends NbRoleProvider {
	getRole() {
		// here you could provide any role based on any auth flow
		return observableOf('guest');
	}
}

export const NB_CORE_PROVIDERS = [
	...NbAuthModule.forRoot({
		strategies: [
			NbPasswordAuthStrategy.setup({
				name: 'email',
				baseEndpoint: environment.apiBaseEndpoint,
				login: {
					endpoint: environment.authEndpoint + 'signin',
					method: 'POST',
					defaultErrors: ['Login/Email combination is not correct, please try again.'],
					redirect: {
						success: '/',
						failure: null,
					},
				},
				logout: {
					endpoint: '',
					method: 'GET',
					redirect: {
						success: '/',
						failure: '/',
					},
					defaultErrors: ['Something went wrong, please try again.'],
					defaultMessages: ['You have been successfully logged out.'],
				},
				refreshToken: {
					endpoint: environment.authEndpoint + 'refresh',
					method: 'put',
					requireValidToken: false,
					redirect: {
						success: null,
						failure: null,
					},
					defaultErrors: ['Something went wrong, please try again.'],
					defaultMessages: ['Your token has been successfully refreshed.'],
				},
				resetPass: {
					endpoint: environment.authEndpoint + 'change_password',
					method: 'PUT',
					defaultErrors: ['Wrong password combination.'],
					redirect: {
						success: '/auth/login',
						failure: null,
					},
				},
				token: {
					class: NbAuthJWTToken,
					getter: getNbAuthJWTToken,
				},
			}),
		],
		forms: {
			login: {
				socialLinks: socialLinks,
			},
			register: {
				socialLinks: socialLinks,
			},
			resetPassword: {
				redirectDelay: 1500,
			},
		},
	}).providers,
	{
		provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
	},
	LayoutService,
	StateService,
	UserApiService,
	ApiService,
	APIS
];

export function getNbAuthJWTToken(module: string, res: HttpResponse<Object>) {
	return res.headers.get(environment.authHeader);
}

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		NbAuthModule,
	],
	declarations: [],
})

export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}
}
