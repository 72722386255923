/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {MinacciaDTO} from '../model/minacciaDTO';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseIterableMinaccia} from '../model/successfulResponseIterableMinaccia';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class MinacciaControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Cancella una minaccia dall&#x27;anagrafe
	 *
	 * @param idMinaccia
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteMinaccia2(idMinaccia: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public deleteMinaccia2(idMinaccia: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public deleteMinaccia2(idMinaccia: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public deleteMinaccia2(idMinaccia: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (idMinaccia === null || idMinaccia === undefined) {
			throw new Error('Required parameter idMinaccia was null or undefined when calling deleteMinaccia2.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (idMinaccia !== undefined && idMinaccia !== null) {
			queryParameters = queryParameters.set('idMinaccia', <any>idMinaccia);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/minaccia`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Modifica una minaccia all&#x27;anagrafe
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public editMinaccia(body: MinacciaDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public editMinaccia(body: MinacciaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public editMinaccia(body: MinacciaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public editMinaccia(body: MinacciaDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling editMinaccia.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('put', `${this.basePath}/api/v1/minaccia`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista delle minacce in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param id Imposta filtro su campo id
	 * @param categoria Imposta filtro su campo categoria
	 * @param nome Imposta filtro su campo nome
	 * @param noteDiLavoro Imposta filtro su campo noteDiLavoro
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllMinacce(page?: number, size?: number, id?: string, categoria?: string, nome?: string, noteDiLavoro?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableMinaccia>;
	public getAllMinacce(page?: number, size?: number, id?: string, categoria?: string, nome?: string, noteDiLavoro?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableMinaccia>>;
	public getAllMinacce(page?: number, size?: number, id?: string, categoria?: string, nome?: string, noteDiLavoro?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableMinaccia>>;
	public getAllMinacce(page?: number, size?: number, id?: string, categoria?: string, nome?: string, noteDiLavoro?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (id !== undefined && id !== null) {
			queryParameters = queryParameters.set('id', <any>id);
		}
		if (categoria !== undefined && categoria !== null) {
			queryParameters = queryParameters.set('categoria', <any>categoria);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (noteDiLavoro !== undefined && noteDiLavoro !== null) {
			queryParameters = queryParameters.set('noteDiLavoro', <any>noteDiLavoro);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableMinaccia>('get', `${this.basePath}/api/v1/minaccia`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Aggiunge una minaccia all&#x27;anagrafe
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public makeNewMinaccia(body: MinacciaDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public makeNewMinaccia(body: MinacciaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public makeNewMinaccia(body: MinacciaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public makeNewMinaccia(body: MinacciaDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling makeNewMinaccia.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/minaccia`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
