/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Applicazione} from './applicazione';
import {ApplicazioneKey} from './applicazioneKey';
import {Asset} from './asset';
import {Bia} from './bia';
import {Cliente} from './cliente';
import {ConfigurazioneDiCalcolo} from './configurazioneDiCalcolo';
import {DashboardDTO} from './dashboardDTO';
import {Evento} from './evento';
import {Informazione} from './informazione';
import {Minaccia} from './minaccia';
import {MinacciaView} from './minacciaView';
import {PerimetroDiCompliance} from './perimetroDiCompliance';
import {PianoDiTrattamentoDTO} from './pianoDiTrattamentoDTO';
import {Processo} from './processo';
import {RequisitoView} from './requisitoView';
import {RischioAppMinacciaAnalisi} from './rischioAppMinacciaAnalisi';
import {RischioAssetMinacciaAnalisi} from './rischioAssetMinacciaAnalisi';
import {RischioProcessoMinacciaAnalisi} from './rischioProcessoMinacciaAnalisi';
import {SerializablePairApplicazioneKeyListAsset} from './serializablePairApplicazioneKeyListAsset';
import {SerializablePairApplicazioneKeyListInformazione} from './serializablePairApplicazioneKeyListInformazione';
import {SerializablePairApplicazioneKeyListMinacciaView} from './serializablePairApplicazioneKeyListMinacciaView';
import {SerializablePairApplicazioneKeyListRequisitoView} from './serializablePairApplicazioneKeyListRequisitoView';
import {SerializablePairApplicazioneKeyListVulnerabilita} from './serializablePairApplicazioneKeyListVulnerabilita';
import {
	SerializablePairApplicazioneKeyListVulnerabilitaView
} from './serializablePairApplicazioneKeyListVulnerabilitaView';
import {
	SerializablePairApplicazioneKeySetPerimetroDiCompliance
} from './serializablePairApplicazioneKeySetPerimetroDiCompliance';
import {VulnerabilitaView} from './vulnerabilitaView';

export interface Analisi {
	codice?: string;
	nome?: string;
	data?: Date;
	dominioDiAnalisi?: string;
	cliente?: Cliente;
	stato?: Analisi.StatoEnum;
	raf?: number;
	noteRaf?: string;
	bia?: Bia;
	companyId?: number;
	isSimulazione?: boolean;
	idLibreria?: number;
	createdAt?: Date;
	modifiedAt?: Date;
	configurazione?: ConfigurazioneDiCalcolo;
	processi?: Array<Processo>;
	applicazioni?: Array<Applicazione>;
	eventi?: Array<Evento>;
	setRischioProcessoMinaccia?: Array<RischioProcessoMinacciaAnalisi>;
	setRischioAppMinaccia?: Array<RischioAppMinacciaAnalisi>;
	setRischioAssetMinaccia?: Array<RischioAssetMinacciaAnalisi>;
	informazioni?: Array<Informazione>;
	mapAssetMinaccia?: { [key: string]: Array<Minaccia>; };
	mapProcessoMinaccia?: { [key: string]: Array<Minaccia>; };
	mapAppVulnerabilita?: Array<SerializablePairApplicazioneKeyListVulnerabilita>;
	ownerUsername?: string;
	ownerId?: number;
	mapAppProcessiView?: { [key: string]: Array<ApplicazioneKey>; };
	hasChecklists?: boolean;
	assets?: Array<Asset>;
	mapAssetInfoView?: { [key: string]: Array<Informazione>; };
	perimetriDiCompliance?: Array<PerimetroDiCompliance>;
	mapAssetPerimetroView?: { [key: string]: Array<PerimetroDiCompliance>; };
	mapProcessoPerimetroView?: { [key: string]: Array<PerimetroDiCompliance>; };
	mapAppPerimetroView?: Array<SerializablePairApplicazioneKeySetPerimetroDiCompliance>;
	mapProcessiInfoView?: { [key: string]: Array<Informazione>; };
	mapAppAssetsView?: Array<SerializablePairApplicazioneKeyListAsset>;
	mapAppMinacciaView?: Array<SerializablePairApplicazioneKeyListMinacciaView>;
	mapAppRequisitoView?: Array<SerializablePairApplicazioneKeyListRequisitoView>;
	mapAssetRequisitoView?: { [key: string]: Array<RequisitoView>; };
	mapAssetMinacciaView?: { [key: string]: Array<MinacciaView>; };
	mapProcessoMinacciaView?: { [key: string]: Array<MinacciaView>; };
	mapProcessoRequisitoView?: { [key: string]: Array<RequisitoView>; };
	mapAppVulnerabilitaView?: Array<SerializablePairApplicazioneKeyListVulnerabilitaView>;
	mapAssetVulnerabilitaView?: { [key: string]: Array<VulnerabilitaView>; };
	mapProcessoVulnerabilitaView?: { [key: string]: Array<VulnerabilitaView>; };
	mapAppInfoView?: Array<SerializablePairApplicazioneKeyListInformazione>;
	percentualeCompletamento?: number;
	RPMax?: number;
	REMax?: number;
	RRPMax?: number;
	RIPMax?: number;
	RDPMax?: number;
	RREMax?: number;
	RIEMax?: number;
	RDEMax?: number;
	RRRMax?: number;
	RIRMax?: number;
	RDRMax?: number;
	RRMax?: number;
	pianoDiTrattamento?: PianoDiTrattamentoDTO;
	statisticheRichieste?: { [key: string]: number; };
	dashboard?: DashboardDTO;
}

export namespace Analisi {
	export type StatoEnum =
		'IN_CREAZIONE'
		| 'ASSOCIA_PROCESSI_ANALISI'
		| 'ASSOCIA_AP_ANALISI'
		| 'ASSOCIA_AP_ASSETS_ANALISI'
		| 'ASSOCIA_PROCESSI_AP_ANALISI'
		| 'ASSOCIA_BIA_ANALISI'
		| 'ASSOCIA_INFO_ANALISI'
		| 'PERIMETRO_ANALISI_CONFERMATO'
		| 'ASSOCIA_PROCESSI_INFO_ANALISI'
		| 'ASSOCIA_AP_INFO_ANALISI'
		| 'ASSOCIA_ASSET_INFO_ANALISI'
		| 'ASSOCIA_PROCESSI_PERIMETRI_ANALISI'
		| 'ASSOCIA_AP_PERIMETRI_ANALISI'
		| 'ASSOCIA_ASSET_PERIMETRI_ANALISI'
		| 'EA_CONFERMATA'
		| 'OVERRIDE_MINACCE_ANALISI'
		| 'OVERRIDE_VULNERABILITA_ANALISI'
		| 'OVERRIDE_PROBABILITA_MINACCIA_TO_ANALISI'
		| 'CALCOLA_RISCHIO_POTENZIALE_IN_CORSO'
		| 'RISCHIO_POTENZIALE_CALCOLATO'
		| 'CALCOLA_CONTROMISURE'
		| 'CONTROMISURE_CALCOLATE'
		| 'OVERRIDE_APPLICABILITA_ANALISI'
		| 'CALCOLA_RISCHIO_EFFETTIVO_IN_CORSO'
		| 'RISCHIO_EFFETTIVO_CALCOLATO'
		| 'CALCOLO_REQUISITI_PER_SIMULAZIONE_IN_CORSO'
		| 'REQUISITI_PER_SIMULAZIONE_GENERATI'
		| 'CALCOLO_RISCHIO_RESIDUO_IN_CORSO'
		| 'RISCHIO_RESIDUO_CALCOLATO'
		| 'COMPLETATA'
		| 'ANALISI_ELIMINATA';
	export const StatoEnum = {
		INCREAZIONE: 'IN_CREAZIONE' as StatoEnum,
		ASSOCIAPROCESSIANALISI: 'ASSOCIA_PROCESSI_ANALISI' as StatoEnum,
		ASSOCIAAPANALISI: 'ASSOCIA_AP_ANALISI' as StatoEnum,
		ASSOCIAAPASSETSANALISI: 'ASSOCIA_AP_ASSETS_ANALISI' as StatoEnum,
		ASSOCIAPROCESSIAPANALISI: 'ASSOCIA_PROCESSI_AP_ANALISI' as StatoEnum,
		ASSOCIABIAANALISI: 'ASSOCIA_BIA_ANALISI' as StatoEnum,
		ASSOCIAINFOANALISI: 'ASSOCIA_INFO_ANALISI' as StatoEnum,
		PERIMETROANALISICONFERMATO: 'PERIMETRO_ANALISI_CONFERMATO' as StatoEnum,
		ASSOCIAPROCESSIINFOANALISI: 'ASSOCIA_PROCESSI_INFO_ANALISI' as StatoEnum,
		ASSOCIAAPINFOANALISI: 'ASSOCIA_AP_INFO_ANALISI' as StatoEnum,
		ASSOCIAASSETINFOANALISI: 'ASSOCIA_ASSET_INFO_ANALISI' as StatoEnum,
		ASSOCIAPROCESSIPERIMETRIANALISI: 'ASSOCIA_PROCESSI_PERIMETRI_ANALISI' as StatoEnum,
		ASSOCIAAPPERIMETRIANALISI: 'ASSOCIA_AP_PERIMETRI_ANALISI' as StatoEnum,
		ASSOCIAASSETPERIMETRIANALISI: 'ASSOCIA_ASSET_PERIMETRI_ANALISI' as StatoEnum,
		EACONFERMATA: 'EA_CONFERMATA' as StatoEnum,
		OVERRIDEMINACCEANALISI: 'OVERRIDE_MINACCE_ANALISI' as StatoEnum,
		OVERRIDEVULNERABILITAANALISI: 'OVERRIDE_VULNERABILITA_ANALISI' as StatoEnum,
		OVERRIDEPROBABILITAMINACCIATOANALISI: 'OVERRIDE_PROBABILITA_MINACCIA_TO_ANALISI' as StatoEnum,
		CALCOLARISCHIOPOTENZIALEINCORSO: 'CALCOLA_RISCHIO_POTENZIALE_IN_CORSO' as StatoEnum,
		RISCHIOPOTENZIALECALCOLATO: 'RISCHIO_POTENZIALE_CALCOLATO' as StatoEnum,
		CALCOLACONTROMISURE: 'CALCOLA_CONTROMISURE' as StatoEnum,
		CONTROMISURECALCOLATE: 'CONTROMISURE_CALCOLATE' as StatoEnum,
		OVERRIDEAPPLICABILITAANALISI: 'OVERRIDE_APPLICABILITA_ANALISI' as StatoEnum,
		CALCOLARISCHIOEFFETTIVOINCORSO: 'CALCOLA_RISCHIO_EFFETTIVO_IN_CORSO' as StatoEnum,
		RISCHIOEFFETTIVOCALCOLATO: 'RISCHIO_EFFETTIVO_CALCOLATO' as StatoEnum,
		CALCOLOREQUISITIPERSIMULAZIONEINCORSO: 'CALCOLO_REQUISITI_PER_SIMULAZIONE_IN_CORSO' as StatoEnum,
		REQUISITIPERSIMULAZIONEGENERATI: 'REQUISITI_PER_SIMULAZIONE_GENERATI' as StatoEnum,
		CALCOLORISCHIORESIDUOINCORSO: 'CALCOLO_RISCHIO_RESIDUO_IN_CORSO' as StatoEnum,
		RISCHIORESIDUOCALCOLATO: 'RISCHIO_RESIDUO_CALCOLATO' as StatoEnum,
		COMPLETATA: 'COMPLETATA' as StatoEnum,
		ANALISIELIMINATA: 'ANALISI_ELIMINATA' as StatoEnum
	};
}