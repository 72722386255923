import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {Configuration} from './configuration';
import {HttpClient} from '@angular/common/http';


import {AnalisiControllerService} from './api/analisiController.service';
import {ApplicazioneControllerService} from './api/applicazioneController.service';
import {AssetControllerService} from './api/assetController.service';
import {AutomatismoControllerService} from './api/automatismoController.service';
import {BiaControllerService} from './api/biaController.service';
import {ChecklistControllerService} from './api/checklistController.service';
import {ClasseFunzionaleControllerService} from './api/classeFunzionaleController.service';
import {ClienteControllerService} from './api/clienteController.service';
import {CopertureControllerService} from './api/copertureController.service';
import {EventoControllerService} from './api/eventoController.service';
import {ExcelControllerService} from './api/excelController.service';
import {FileUploadControllerService} from './api/fileUploadController.service';
import {InformazioneControllerService} from './api/informazioneController.service';
import {LayerControllerService} from './api/layerController.service';
import {LibreriaContromisureControllerService} from './api/libreriaContromisureController.service';
import {LookupBiaOteControllerService} from './api/lookupBiaOteController.service';
import {MailControllerService} from './api/mailController.service';
import {MinacciaControllerService} from './api/minacciaController.service';
import {OteControllerService} from './api/oteController.service';
import {PerimetroDiComplianceControllerService} from './api/perimetroDiComplianceController.service';
import {PpswebControllerService} from './api/ppswebController.service';
import {PrivilegeControllerService} from './api/privilegeController.service';
import {ProcessoControllerService} from './api/processoController.service';
import {ReportisticaControllerService} from './api/reportisticaController.service';
import {RichiestaControllerService} from './api/richiestaController.service';
import {ScheduledTasksControllerService} from './api/scheduledTasksController.service';
import {SwitchControllerService} from './api/switchController.service';
import {SyncControllerService} from './api/syncController.service';
import {TipologieControllerService} from './api/tipologieController.service';
import {VulnerabilitaControllerService} from './api/vulnerabilitaController.service';

@NgModule({
	imports: [],
	declarations: [],
	exports: [],
	providers: [
		AnalisiControllerService,
		ApplicazioneControllerService,
		AssetControllerService,
		AutomatismoControllerService,
		BiaControllerService,
		ChecklistControllerService,
		ClasseFunzionaleControllerService,
		ClienteControllerService,
		CopertureControllerService,
		EventoControllerService,
		ExcelControllerService,
		FileUploadControllerService,
		InformazioneControllerService,
		LayerControllerService,
		LibreriaContromisureControllerService,
		LookupBiaOteControllerService,
		MailControllerService,
		MinacciaControllerService,
		OteControllerService,
		PerimetroDiComplianceControllerService,
		PpswebControllerService,
		PrivilegeControllerService,
		ProcessoControllerService,
		ReportisticaControllerService,
		RichiestaControllerService,
		ScheduledTasksControllerService,
		SwitchControllerService,
		SyncControllerService,
		TipologieControllerService,
		VulnerabilitaControllerService]
})
export class ApiModule {
	public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
		return {
			ngModule: ApiModule,
			providers: [{provide: Configuration, useFactory: configurationFactory}]
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: ApiModule,
							@Optional() http: HttpClient) {
		if (parentModule) {
			throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
		}
		if (!http) {
			throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
				'See also https://github.com/angular/angular/issues/20575');
		}
	}
}
