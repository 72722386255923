/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Richiesta {
	id?: number;
	gradoDiApplicabilitaSuggerito?: Richiesta.GradoDiApplicabilitaSuggeritoEnum;
	note?: string;
	stato?: Richiesta.StatoEnum;
	createdAt?: Date;
	updatedAt?: Date;
	expiresAt?: Date;
	ownerRichiestaDisplayName?: string;
	ownerRichiestaId?: number;
	assegnatarioRichiestaDisplayName?: string;
	assegnatarioRichiestaId?: number;
}

export namespace Richiesta {
	export type GradoDiApplicabilitaSuggeritoEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoDiApplicabilitaSuggeritoEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoDiApplicabilitaSuggeritoEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoDiApplicabilitaSuggeritoEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoDiApplicabilitaSuggeritoEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoDiApplicabilitaSuggeritoEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoDiApplicabilitaSuggeritoEnum
	};
	export type StatoEnum =
		'IN_ATTESA_NOTIFICA'
		| 'RICHIESTA_CANCELLATA'
		| 'RICHIESTA_SCADUTA'
		| 'INVIATA_AD_OWNER'
		| 'COMPLETATA';
	export const StatoEnum = {
		INATTESANOTIFICA: 'IN_ATTESA_NOTIFICA' as StatoEnum,
		RICHIESTACANCELLATA: 'RICHIESTA_CANCELLATA' as StatoEnum,
		RICHIESTASCADUTA: 'RICHIESTA_SCADUTA' as StatoEnum,
		INVIATAADOWNER: 'INVIATA_AD_OWNER' as StatoEnum,
		COMPLETATA: 'COMPLETATA' as StatoEnum
	};
}