<h1 id="title" class="title">Change password</h1>
<p class="sub-title">Please set a new password</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Perfect!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-oldPassword">Current Password:</label>
    <input nbInput
           [(ngModel)]="user.oldPassword"
           #oldPassword="ngModel"
           type="password"
           id="input-oldPassword"
           name="oldPassword"
           class="first"
           placeholder="Password attuale"
           autofocus
           fullWidth
           [status]="oldPassword.dirty ? (oldPassword.invalid  ? 'danger' : 'success') : ''"
           [required]="getConfigValue('forms.validation.oldPassword.required')"
           [minlength]="getConfigValue('forms.validation.oldPassword.minLength')"
           [maxlength]="getConfigValue('forms.validation.oldPassword.maxLength')">
    <small class="form-text error" *ngIf="oldPassword.invalid && oldPassword.touched && oldPassword.errors?.required">
      Current password is required!
    </small>
    <small
      class="form-text error"
      *ngIf="oldPassword.invalid && oldPassword.touched && (oldPassword.errors?.minlength || oldPassword.errors?.maxlength)">
      Current password should contains
      from {{getConfigValue('forms.validation.oldPassword.minLength')}}
      to {{getConfigValue('forms.validation.oldPassword.maxLength')}}
      characters
    </small>
  </div>


  <div class="form-control-group">
    <label class="label" for="input-password">New Password:</label>
    <input nbInput
           [(ngModel)]="user.newPassword"
           #newPassword="ngModel"
           type="password"
           id="input-password"
           name="password"
           class="first"
           placeholder="New Password"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="newPassword.dirty ? (newPassword.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="newPassword.invalid && newPassword.touched ? true : null">
    <ng-container *ngIf="newPassword.invalid && newPassword.touched">
      <p class="caption status-danger" *ngIf="newPassword.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="newPassword.errors?.minlength || newPassword.errors?.maxlength">
        Password should contains
        from {{getConfigValue('forms.validation.password.minLength')}}
        to {{getConfigValue('forms.validation.password.maxLength')}}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-confirmPassword">Confirm Password:</label>
    <input nbInput
           [(ngModel)]="user.confirmPassword"
           #confirmPassword="ngModel"
           id="input-confirmPassword"
           name="rePass"
           type="password"
           class="last"
           placeholder="Confirm Password"
           fullWidth
           fieldSize="large"
           [status]="confirmPassword.touched
               ? (confirmPassword.invalid || confirmPassword.value != confirmPassword.value ? 'danger' : 'success')
               : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [attr.aria-invalid]="confirmPassword.invalid && confirmPassword.touched ? true : null">
    <ng-container *ngIf="confirmPassword.touched">
      <p class="caption status-danger" *ngIf="confirmPassword.invalid && confirmPassword.errors?.required">
        Password confirmation is required!
      </p>
      <p class="caption status-danger"
         *ngIf="confirmPassword.value != confirmPassword.value && !confirmPassword.errors?.required">
        Password does not match the confirm password.
      </p>
    </ng-container>
  </div>

  <button nbButton
          status="primary"
          fullWidth
          size="large"
          [disabled]="submitted || !resetPassForm.valid"
          [class.btn-pulse]="submitted">
    Change password
  </button>
</form>
