/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ContromisuraStagingKey {
	id: number;
	fonte: ContromisuraStagingKey.FonteEnum;
}

export namespace ContromisuraStagingKey {
	export type FonteEnum = 'ADR' | 'PPSWEB' | 'MONPDR' | 'SECURITY_BY_DESIGN';
	export const FonteEnum = {
		ADR: 'ADR' as FonteEnum,
		PPSWEB: 'PPSWEB' as FonteEnum,
		MONPDR: 'MONPDR' as FonteEnum,
		SECURITYBYDESIGN: 'SECURITY_BY_DESIGN' as FonteEnum
	};
}