/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AggiornaRichiestaRequisitoDTO {
	idRichiesta?: number;
	grado?: AggiornaRichiestaRequisitoDTO.GradoEnum;
	note?: string;
}

export namespace AggiornaRichiestaRequisitoDTO {
	export type GradoEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoEnum
	};
}