/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {LinkRequisitoAssetAnalisiKey} from './linkRequisitoAssetAnalisiKey';
import {Richiesta} from './richiesta';

export interface LinkRequisitoAssetAnalisi {
	key?: LinkRequisitoAssetAnalisiKey;
	gradoDiApplicabilita?: LinkRequisitoAssetAnalisi.GradoDiApplicabilitaEnum;
	richiesta?: Richiesta;
	fonte?: LinkRequisitoAssetAnalisi.FonteEnum;
}

export namespace LinkRequisitoAssetAnalisi {
	export type GradoDiApplicabilitaEnum =
		'SCONOSCIUTO'
		| 'NON_APPLICABILE'
		| 'NON_IMPLEMENTATO'
		| 'PARZIALMENTE_IMPLEMENTATO'
		| 'IMPLEMENTATO';
	export const GradoDiApplicabilitaEnum = {
		SCONOSCIUTO: 'SCONOSCIUTO' as GradoDiApplicabilitaEnum,
		NONAPPLICABILE: 'NON_APPLICABILE' as GradoDiApplicabilitaEnum,
		NONIMPLEMENTATO: 'NON_IMPLEMENTATO' as GradoDiApplicabilitaEnum,
		PARZIALMENTEIMPLEMENTATO: 'PARZIALMENTE_IMPLEMENTATO' as GradoDiApplicabilitaEnum,
		IMPLEMENTATO: 'IMPLEMENTATO' as GradoDiApplicabilitaEnum
	};
	export type FonteEnum = 'ADR' | 'PPSWEB' | 'MONPDR' | 'SECURITY_BY_DESIGN';
	export const FonteEnum = {
		ADR: 'ADR' as FonteEnum,
		PPSWEB: 'PPSWEB' as FonteEnum,
		MONPDR: 'MONPDR' as FonteEnum,
		SECURITYBYDESIGN: 'SECURITY_BY_DESIGN' as FonteEnum
	};
}