/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Attribute {
	id?: number;
	name?: string;
	area?: string;
	status?: number;
	datatype?: number;
	idCompany?: number;
	libraryCloningRequired?: boolean;
	idRequirement?: number;
	valueString?: string;
	valueLong?: number;
	valueTimestamp?: Date;
}