/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {ContromisuraStaging} from '../model/contromisuraStaging';
import {ContromisurePpsweb} from '../model/contromisurePpsweb';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {
	SuccessfulResponseIterableContromisuraApplicazione
} from '../model/successfulResponseIterableContromisuraApplicazione';
import {SuccessfulResponseIterableContromisuraAsset} from '../model/successfulResponseIterableContromisuraAsset';
import {SuccessfulResponseIterableContromisuraProcesso} from '../model/successfulResponseIterableContromisuraProcesso';
import {SuccessfulResponseIterableContromisuraStaging} from '../model/successfulResponseIterableContromisuraStaging';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class CopertureControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua il get della lista degli ContromisureStaging in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param id id
	 * @param fonti fonti coperture
	 * @param codiceApplicazione codiceApplicazione
	 * @param codiceLayer codiceLayer
	 * @param codiceAsset codiceAsset
	 * @param nomeAsset nomeAsset
	 * @param codiceRequisito codiceRequisito
	 * @param coperturaAttuale coperturaAttuale
	 * @param fromCreatedAt Imposta filtro per le coperture create dalla data fromCreatedAt
	 * @param toCreatedAt Imposta filtro per le coperture fino alla data toCreatedAt
	 * @param fromUpdatedAt Imposta filtro per le coperture fino alla data fromUpdatedAt
	 * @param toUpdatedAt Imposta filtro per le coperture fino alla data toUpdatedAt
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllContromisuraStaging(page?: number, size?: number, id?: number, fonti?: Array<string>, codiceApplicazione?: string, codiceLayer?: string, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, coperturaAttuale?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableContromisuraStaging>;
	public getAllContromisuraStaging(page?: number, size?: number, id?: number, fonti?: Array<string>, codiceApplicazione?: string, codiceLayer?: string, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, coperturaAttuale?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableContromisuraStaging>>;
	public getAllContromisuraStaging(page?: number, size?: number, id?: number, fonti?: Array<string>, codiceApplicazione?: string, codiceLayer?: string, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, coperturaAttuale?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableContromisuraStaging>>;
	public getAllContromisuraStaging(page?: number, size?: number, id?: number, fonti?: Array<string>, codiceApplicazione?: string, codiceLayer?: string, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, coperturaAttuale?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (id !== undefined && id !== null) {
			queryParameters = queryParameters.set('id', <any>id);
		}
		if (fonti) {
			fonti.forEach((element) => {
				queryParameters = queryParameters.append('fonti', <any>element);
			})
		}
		if (codiceApplicazione !== undefined && codiceApplicazione !== null) {
			queryParameters = queryParameters.set('codiceApplicazione', <any>codiceApplicazione);
		}
		if (codiceLayer !== undefined && codiceLayer !== null) {
			queryParameters = queryParameters.set('codiceLayer', <any>codiceLayer);
		}
		if (codiceAsset !== undefined && codiceAsset !== null) {
			queryParameters = queryParameters.set('codiceAsset', <any>codiceAsset);
		}
		if (nomeAsset !== undefined && nomeAsset !== null) {
			queryParameters = queryParameters.set('nomeAsset', <any>nomeAsset);
		}
		if (codiceRequisito !== undefined && codiceRequisito !== null) {
			queryParameters = queryParameters.set('codiceRequisito', <any>codiceRequisito);
		}
		if (coperturaAttuale !== undefined && coperturaAttuale !== null) {
			queryParameters = queryParameters.set('coperturaAttuale', <any>coperturaAttuale);
		}
		if (fromCreatedAt !== undefined && fromCreatedAt !== null) {
			queryParameters = queryParameters.set('fromCreatedAt', <any>fromCreatedAt.toISOString());
		}
		if (toCreatedAt !== undefined && toCreatedAt !== null) {
			queryParameters = queryParameters.set('toCreatedAt', <any>toCreatedAt.toISOString());
		}
		if (fromUpdatedAt !== undefined && fromUpdatedAt !== null) {
			queryParameters = queryParameters.set('fromUpdatedAt', <any>fromUpdatedAt.toISOString());
		}
		if (toUpdatedAt !== undefined && toUpdatedAt !== null) {
			queryParameters = queryParameters.set('toUpdatedAt', <any>toUpdatedAt.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableContromisuraStaging>('get', `${this.basePath}/api/v1/coperture/external`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista delle coperture in anagrafica AdR per le Applicazioni
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codice codice
	 * @param codicePacchetto codicePacchetto
	 * @param nome nome
	 * @param codiceRequisito codiceRequisito
	 * @param codiceAnalisi codiceAnalisi
	 * @param gradoApplicabilita gradoApplicabilita
	 * @param fromCreatedAt Imposta filtro per le coperture create dalla data fromCreatedAt
	 * @param toCreatedAt Imposta filtro per le coperture fino alla data toCreatedAt
	 * @param fromUpdatedAt Imposta filtro per le coperture fino alla data fromUpdatedAt
	 * @param toUpdatedAt Imposta filtro per le coperture fino alla data toUpdatedAt
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllContromisureAdrApplicazioni(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableContromisuraApplicazione>;
	public getAllContromisureAdrApplicazioni(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableContromisuraApplicazione>>;
	public getAllContromisureAdrApplicazioni(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableContromisuraApplicazione>>;
	public getAllContromisureAdrApplicazioni(page?: number, size?: number, codice?: string, codicePacchetto?: string, nome?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codice !== undefined && codice !== null) {
			queryParameters = queryParameters.set('codice', <any>codice);
		}
		if (codicePacchetto !== undefined && codicePacchetto !== null) {
			queryParameters = queryParameters.set('codicePacchetto', <any>codicePacchetto);
		}
		if (nome !== undefined && nome !== null) {
			queryParameters = queryParameters.set('nome', <any>nome);
		}
		if (codiceRequisito !== undefined && codiceRequisito !== null) {
			queryParameters = queryParameters.set('codiceRequisito', <any>codiceRequisito);
		}
		if (codiceAnalisi !== undefined && codiceAnalisi !== null) {
			queryParameters = queryParameters.set('codiceAnalisi', <any>codiceAnalisi);
		}
		if (gradoApplicabilita !== undefined && gradoApplicabilita !== null) {
			queryParameters = queryParameters.set('gradoApplicabilita', <any>gradoApplicabilita);
		}
		if (fromCreatedAt !== undefined && fromCreatedAt !== null) {
			queryParameters = queryParameters.set('fromCreatedAt', <any>fromCreatedAt.toISOString());
		}
		if (toCreatedAt !== undefined && toCreatedAt !== null) {
			queryParameters = queryParameters.set('toCreatedAt', <any>toCreatedAt.toISOString());
		}
		if (fromUpdatedAt !== undefined && fromUpdatedAt !== null) {
			queryParameters = queryParameters.set('fromUpdatedAt', <any>fromUpdatedAt.toISOString());
		}
		if (toUpdatedAt !== undefined && toUpdatedAt !== null) {
			queryParameters = queryParameters.set('toUpdatedAt', <any>toUpdatedAt.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableContromisuraApplicazione>('get', `${this.basePath}/api/v1/coperture/applicazioni`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista delle coperture in anagrafica AdR per gli assets
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codiceAsset codiceAsset
	 * @param nomeAsset nomeAsset
	 * @param codiceRequisito codiceRequisito
	 * @param codiceAnalisi codiceAnalisi
	 * @param gradoApplicabilita gradoApplicabilita
	 * @param fromCreatedAt Imposta filtro per le coperture create dalla data fromCreatedAt
	 * @param toCreatedAt Imposta filtro per le coperture fino alla data toCreatedAt
	 * @param fromUpdatedAt Imposta filtro per le coperture fino alla data fromUpdatedAt
	 * @param toUpdatedAt Imposta filtro per le coperture fino alla data toUpdatedAt
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllContromisureAdrAssets(page?: number, size?: number, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableContromisuraAsset>;
	public getAllContromisureAdrAssets(page?: number, size?: number, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableContromisuraAsset>>;
	public getAllContromisureAdrAssets(page?: number, size?: number, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableContromisuraAsset>>;
	public getAllContromisureAdrAssets(page?: number, size?: number, codiceAsset?: string, nomeAsset?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codiceAsset !== undefined && codiceAsset !== null) {
			queryParameters = queryParameters.set('codiceAsset', <any>codiceAsset);
		}
		if (nomeAsset !== undefined && nomeAsset !== null) {
			queryParameters = queryParameters.set('nomeAsset', <any>nomeAsset);
		}
		if (codiceRequisito !== undefined && codiceRequisito !== null) {
			queryParameters = queryParameters.set('codiceRequisito', <any>codiceRequisito);
		}
		if (codiceAnalisi !== undefined && codiceAnalisi !== null) {
			queryParameters = queryParameters.set('codiceAnalisi', <any>codiceAnalisi);
		}
		if (gradoApplicabilita !== undefined && gradoApplicabilita !== null) {
			queryParameters = queryParameters.set('gradoApplicabilita', <any>gradoApplicabilita);
		}
		if (fromCreatedAt !== undefined && fromCreatedAt !== null) {
			queryParameters = queryParameters.set('fromCreatedAt', <any>fromCreatedAt.toISOString());
		}
		if (toCreatedAt !== undefined && toCreatedAt !== null) {
			queryParameters = queryParameters.set('toCreatedAt', <any>toCreatedAt.toISOString());
		}
		if (fromUpdatedAt !== undefined && fromUpdatedAt !== null) {
			queryParameters = queryParameters.set('fromUpdatedAt', <any>fromUpdatedAt.toISOString());
		}
		if (toUpdatedAt !== undefined && toUpdatedAt !== null) {
			queryParameters = queryParameters.set('toUpdatedAt', <any>toUpdatedAt.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableContromisuraAsset>('get', `${this.basePath}/api/v1/coperture/asset`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista delle coperture in anagrafica AdR per i processi
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param codiceProcesso codiceProcesso
	 * @param nomeProcesso nomeProcesso
	 * @param codiceRequisito codiceRequisito
	 * @param codiceAnalisi codiceAnalisi
	 * @param gradoApplicabilita gradoApplicabilita
	 * @param fromCreatedAt Imposta filtro per le coperture create dalla data fromCreatedAt
	 * @param toCreatedAt Imposta filtro per le coperture fino alla data toCreatedAt
	 * @param fromUpdatedAt Imposta filtro per le coperture fino alla data fromUpdatedAt
	 * @param toUpdatedAt Imposta filtro per le coperture fino alla data toUpdatedAt
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllContromisureAdrProcessi(page?: number, size?: number, codiceProcesso?: string, nomeProcesso?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableContromisuraProcesso>;
	public getAllContromisureAdrProcessi(page?: number, size?: number, codiceProcesso?: string, nomeProcesso?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableContromisuraProcesso>>;
	public getAllContromisureAdrProcessi(page?: number, size?: number, codiceProcesso?: string, nomeProcesso?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableContromisuraProcesso>>;
	public getAllContromisureAdrProcessi(page?: number, size?: number, codiceProcesso?: string, nomeProcesso?: string, codiceRequisito?: string, codiceAnalisi?: string, gradoApplicabilita?: string, fromCreatedAt?: Date, toCreatedAt?: Date, fromUpdatedAt?: Date, toUpdatedAt?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (codiceProcesso !== undefined && codiceProcesso !== null) {
			queryParameters = queryParameters.set('codiceProcesso', <any>codiceProcesso);
		}
		if (nomeProcesso !== undefined && nomeProcesso !== null) {
			queryParameters = queryParameters.set('nomeProcesso', <any>nomeProcesso);
		}
		if (codiceRequisito !== undefined && codiceRequisito !== null) {
			queryParameters = queryParameters.set('codiceRequisito', <any>codiceRequisito);
		}
		if (codiceAnalisi !== undefined && codiceAnalisi !== null) {
			queryParameters = queryParameters.set('codiceAnalisi', <any>codiceAnalisi);
		}
		if (gradoApplicabilita !== undefined && gradoApplicabilita !== null) {
			queryParameters = queryParameters.set('gradoApplicabilita', <any>gradoApplicabilita);
		}
		if (fromCreatedAt !== undefined && fromCreatedAt !== null) {
			queryParameters = queryParameters.set('fromCreatedAt', <any>fromCreatedAt.toISOString());
		}
		if (toCreatedAt !== undefined && toCreatedAt !== null) {
			queryParameters = queryParameters.set('toCreatedAt', <any>toCreatedAt.toISOString());
		}
		if (fromUpdatedAt !== undefined && fromUpdatedAt !== null) {
			queryParameters = queryParameters.set('fromUpdatedAt', <any>fromUpdatedAt.toISOString());
		}
		if (toUpdatedAt !== undefined && toUpdatedAt !== null) {
			queryParameters = queryParameters.set('toUpdatedAt', <any>toUpdatedAt.toISOString());
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableContromisuraProcesso>('get', `${this.basePath}/api/v1/coperture/processi`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il popolamento delle contromisure dal sistema SecurityByDesign
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getCopertureSecurityByDesign(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public getCopertureSecurityByDesign(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public getCopertureSecurityByDesign(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public getCopertureSecurityByDesign(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/coperture/securityByDesign`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua l&#x27;inserimento di una contromisuraStaging
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public newContromisura(body: ContromisuraStaging, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public newContromisura(body: ContromisuraStaging, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public newContromisura(body: ContromisuraStaging, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public newContromisura(body: ContromisuraStaging, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling newContromisura.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/coperture`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il reperimento delle contromisure dal sistema PPSWEB
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public populateContromisurePpsweb(observe?: 'body', reportProgress?: boolean): Observable<Array<ContromisurePpsweb>>;
	public populateContromisurePpsweb(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ContromisurePpsweb>>>;
	public populateContromisurePpsweb(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ContromisurePpsweb>>>;
	public populateContromisurePpsweb(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<Array<ContromisurePpsweb>>('get', `${this.basePath}/api/v1/coperture/ppsweb`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
