/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Bia {
	id?: number;
	projectName?: string;
	projectCode?: string;
	biaCode?: number;
	companyId?: number;
	informazioniScaricate?: boolean;
	compiledAt?: Date;
	validatedAt?: Date;
}