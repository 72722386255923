/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {PageOteSummaryEntry} from '../model/pageOteSummaryEntry';
import {PageRequisitoOte} from '../model/pageRequisitoOte';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class OteControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Effettua il get della lista OTE validate in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllLibrerie(page: number, size: number, observe?: 'body', reportProgress?: boolean): Observable<PageOteSummaryEntry>;
	public getAllLibrerie(page: number, size: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOteSummaryEntry>>;
	public getAllLibrerie(page: number, size: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOteSummaryEntry>>;
	public getAllLibrerie(page: number, size: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (page === null || page === undefined) {
			throw new Error('Required parameter page was null or undefined when calling getAllLibrerie.');
		}

		if (size === null || size === undefined) {
			throw new Error('Required parameter size was null or undefined when calling getAllLibrerie.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<PageOteSummaryEntry>('get', `${this.basePath}/api/v1/ote/list`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get dei requisiti OTE validate in anagrafica
	 *
	 * @param idLibrary quantità degli elementi da richiedere
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRequisitiLibreria(idLibrary: number, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageRequisitoOte>;
	public getRequisitiLibreria(idLibrary: number, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageRequisitoOte>>;
	public getRequisitiLibreria(idLibrary: number, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageRequisitoOte>>;
	public getRequisitiLibreria(idLibrary: number, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (idLibrary === null || idLibrary === undefined) {
			throw new Error('Required parameter idLibrary was null or undefined when calling getRequisitiLibreria.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (idLibrary !== undefined && idLibrary !== null) {
			queryParameters = queryParameters.set('idLibrary', <any>idLibrary);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<PageRequisitoOte>('get', `${this.basePath}/api/v1/ote/requisiti`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get dei requisiti OTE on demand
	 *
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public populateRequisiti(observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public populateRequisiti(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public populateRequisiti(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public populateRequisiti(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/ote/populateRequisiti`,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
