/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Status {
	id?: number;
	code?: Status.CodeEnum;
	type?: Status.TypeEnum;
	message?: string;
}

export namespace Status {
	export type CodeEnum =
		'GEN_100'
		| 'GEN_200'
		| 'GEN_500'
		| 'GEN_501'
		| 'GEN_504'
		| 'GEN_505'
		| 'GEN_506'
		| 'GEN_507'
		| 'GEN_508'
		| 'GEN_509'
		| 'GEN_510'
		| 'GEN_511'
		| 'GEN_512'
		| 'GEN_514'
		| 'GEN_520'
		| 'GEN_525'
		| 'GEN_526'
		| 'GEN_527'
		| 'GEN_528'
		| 'GEN_529'
		| 'GEN_530'
		| 'GEN_535'
		| 'GEN_541'
		| 'GEN_542'
		| 'GEN_543'
		| 'GEN_544'
		| 'GEN_545'
		| 'GEN_548'
		| 'GEN_550'
		| 'GEN_554'
		| 'GEN_560'
		| 'USER_500'
		| 'USER_501'
		| 'USER_502'
		| 'USER_503'
		| 'USER_504'
		| 'ADR_001'
		| 'ADR_002'
		| 'ADR_003'
		| 'ADR_004'
		| 'ADR_005'
		| 'ADR_006'
		| 'ADR_007'
		| 'ADR_008'
		| 'ADR_009'
		| 'ADR_010'
		| 'ADR_011'
		| 'ADR_012'
		| 'ADR_013'
		| 'ADR_014'
		| 'ADR_015'
		| 'ADR_016'
		| 'ADR_017'
		| 'ADR_018'
		| 'ADR_019'
		| 'ADR_020'
		| 'ADR_021'
		| 'ADR_022'
		| 'ADR_023'
		| 'ADR_024'
		| 'ADR_025'
		| 'ADR_026'
		| 'ADR_027'
		| 'ADR_028'
		| 'ADR_029'
		| 'ADR_030'
		| 'ADR_031'
		| 'ADR_032'
		| 'ADR_033'
		| 'ADR_034'
		| 'ADR_035'
		| 'ADR_036'
		| 'ADR_037'
		| 'ADR_038'
		| 'ADR_039'
		| 'ADR_040'
		| 'ADR_041'
		| 'ADR_042'
		| 'ADR_043'
		| 'ADR_044'
		| 'ADR_045'
		| 'ADR_046'
		| 'ADR_047'
		| 'ADR_048'
		| 'ADR_049'
		| 'ADR_050'
		| 'ADR_051'
		| 'ADR_052'
		| 'ADR_053'
		| 'ADR_054'
		| 'ADR_055'
		| 'ADR_056'
		| 'ADR_057'
		| 'ADR_058'
		| 'ADR_059'
		| 'ADR_060'
		| 'ADR_061'
		| 'ADR_062'
		| 'ADR_063'
		| 'ADR_064'
		| 'ADR_065'
		| 'ADR_066'
		| 'ADR_067'
		| 'ADR_068'
		| 'ADR_069'
		| 'ADR_070'
		| 'ADR_071'
		| 'ADR_072'
		| 'ADR_073'
		| 'ADR_074'
		| 'ADR_075'
		| 'ADR_076'
		| 'MAIL_400'
		| 'MAIL_401'
		| 'MAIL_402'
		| 'MAIL_403'
		| 'MAIL_404'
		| 'REP_500'
		| 'REP_501'
		| 'REP_502'
		| 'BIA_501'
		| 'BIA_602'
		| 'BIA_603';
	export const CodeEnum = {
		GEN100: 'GEN_100' as CodeEnum,
		GEN200: 'GEN_200' as CodeEnum,
		GEN500: 'GEN_500' as CodeEnum,
		GEN501: 'GEN_501' as CodeEnum,
		GEN504: 'GEN_504' as CodeEnum,
		GEN505: 'GEN_505' as CodeEnum,
		GEN506: 'GEN_506' as CodeEnum,
		GEN507: 'GEN_507' as CodeEnum,
		GEN508: 'GEN_508' as CodeEnum,
		GEN509: 'GEN_509' as CodeEnum,
		GEN510: 'GEN_510' as CodeEnum,
		GEN511: 'GEN_511' as CodeEnum,
		GEN512: 'GEN_512' as CodeEnum,
		GEN514: 'GEN_514' as CodeEnum,
		GEN520: 'GEN_520' as CodeEnum,
		GEN525: 'GEN_525' as CodeEnum,
		GEN526: 'GEN_526' as CodeEnum,
		GEN527: 'GEN_527' as CodeEnum,
		GEN528: 'GEN_528' as CodeEnum,
		GEN529: 'GEN_529' as CodeEnum,
		GEN530: 'GEN_530' as CodeEnum,
		GEN535: 'GEN_535' as CodeEnum,
		GEN541: 'GEN_541' as CodeEnum,
		GEN542: 'GEN_542' as CodeEnum,
		GEN543: 'GEN_543' as CodeEnum,
		GEN544: 'GEN_544' as CodeEnum,
		GEN545: 'GEN_545' as CodeEnum,
		GEN548: 'GEN_548' as CodeEnum,
		GEN550: 'GEN_550' as CodeEnum,
		GEN554: 'GEN_554' as CodeEnum,
		GEN560: 'GEN_560' as CodeEnum,
		USER500: 'USER_500' as CodeEnum,
		USER501: 'USER_501' as CodeEnum,
		USER502: 'USER_502' as CodeEnum,
		USER503: 'USER_503' as CodeEnum,
		USER504: 'USER_504' as CodeEnum,
		ADR001: 'ADR_001' as CodeEnum,
		ADR002: 'ADR_002' as CodeEnum,
		ADR003: 'ADR_003' as CodeEnum,
		ADR004: 'ADR_004' as CodeEnum,
		ADR005: 'ADR_005' as CodeEnum,
		ADR006: 'ADR_006' as CodeEnum,
		ADR007: 'ADR_007' as CodeEnum,
		ADR008: 'ADR_008' as CodeEnum,
		ADR009: 'ADR_009' as CodeEnum,
		ADR010: 'ADR_010' as CodeEnum,
		ADR011: 'ADR_011' as CodeEnum,
		ADR012: 'ADR_012' as CodeEnum,
		ADR013: 'ADR_013' as CodeEnum,
		ADR014: 'ADR_014' as CodeEnum,
		ADR015: 'ADR_015' as CodeEnum,
		ADR016: 'ADR_016' as CodeEnum,
		ADR017: 'ADR_017' as CodeEnum,
		ADR018: 'ADR_018' as CodeEnum,
		ADR019: 'ADR_019' as CodeEnum,
		ADR020: 'ADR_020' as CodeEnum,
		ADR021: 'ADR_021' as CodeEnum,
		ADR022: 'ADR_022' as CodeEnum,
		ADR023: 'ADR_023' as CodeEnum,
		ADR024: 'ADR_024' as CodeEnum,
		ADR025: 'ADR_025' as CodeEnum,
		ADR026: 'ADR_026' as CodeEnum,
		ADR027: 'ADR_027' as CodeEnum,
		ADR028: 'ADR_028' as CodeEnum,
		ADR029: 'ADR_029' as CodeEnum,
		ADR030: 'ADR_030' as CodeEnum,
		ADR031: 'ADR_031' as CodeEnum,
		ADR032: 'ADR_032' as CodeEnum,
		ADR033: 'ADR_033' as CodeEnum,
		ADR034: 'ADR_034' as CodeEnum,
		ADR035: 'ADR_035' as CodeEnum,
		ADR036: 'ADR_036' as CodeEnum,
		ADR037: 'ADR_037' as CodeEnum,
		ADR038: 'ADR_038' as CodeEnum,
		ADR039: 'ADR_039' as CodeEnum,
		ADR040: 'ADR_040' as CodeEnum,
		ADR041: 'ADR_041' as CodeEnum,
		ADR042: 'ADR_042' as CodeEnum,
		ADR043: 'ADR_043' as CodeEnum,
		ADR044: 'ADR_044' as CodeEnum,
		ADR045: 'ADR_045' as CodeEnum,
		ADR046: 'ADR_046' as CodeEnum,
		ADR047: 'ADR_047' as CodeEnum,
		ADR048: 'ADR_048' as CodeEnum,
		ADR049: 'ADR_049' as CodeEnum,
		ADR050: 'ADR_050' as CodeEnum,
		ADR051: 'ADR_051' as CodeEnum,
		ADR052: 'ADR_052' as CodeEnum,
		ADR053: 'ADR_053' as CodeEnum,
		ADR054: 'ADR_054' as CodeEnum,
		ADR055: 'ADR_055' as CodeEnum,
		ADR056: 'ADR_056' as CodeEnum,
		ADR057: 'ADR_057' as CodeEnum,
		ADR058: 'ADR_058' as CodeEnum,
		ADR059: 'ADR_059' as CodeEnum,
		ADR060: 'ADR_060' as CodeEnum,
		ADR061: 'ADR_061' as CodeEnum,
		ADR062: 'ADR_062' as CodeEnum,
		ADR063: 'ADR_063' as CodeEnum,
		ADR064: 'ADR_064' as CodeEnum,
		ADR065: 'ADR_065' as CodeEnum,
		ADR066: 'ADR_066' as CodeEnum,
		ADR067: 'ADR_067' as CodeEnum,
		ADR068: 'ADR_068' as CodeEnum,
		ADR069: 'ADR_069' as CodeEnum,
		ADR070: 'ADR_070' as CodeEnum,
		ADR071: 'ADR_071' as CodeEnum,
		ADR072: 'ADR_072' as CodeEnum,
		ADR073: 'ADR_073' as CodeEnum,
		ADR074: 'ADR_074' as CodeEnum,
		ADR075: 'ADR_075' as CodeEnum,
		ADR076: 'ADR_076' as CodeEnum,
		MAIL400: 'MAIL_400' as CodeEnum,
		MAIL401: 'MAIL_401' as CodeEnum,
		MAIL402: 'MAIL_402' as CodeEnum,
		MAIL403: 'MAIL_403' as CodeEnum,
		MAIL404: 'MAIL_404' as CodeEnum,
		REP500: 'REP_500' as CodeEnum,
		REP501: 'REP_501' as CodeEnum,
		REP502: 'REP_502' as CodeEnum,
		BIA501: 'BIA_501' as CodeEnum,
		BIA602: 'BIA_602' as CodeEnum,
		BIA603: 'BIA_603' as CodeEnum
	};
	export type TypeEnum =
		'SUCCESS'
		| 'ERROR'
		| 'UNAUTHORIZED'
		| 'BAD_REQUEST'
		| 'INTERNAL_ERROR'
		| 'INFORMATION'
		| 'NOT_FOUND'
		| 'CONFLICT';
	export const TypeEnum = {
		SUCCESS: 'SUCCESS' as TypeEnum,
		ERROR: 'ERROR' as TypeEnum,
		UNAUTHORIZED: 'UNAUTHORIZED' as TypeEnum,
		BADREQUEST: 'BAD_REQUEST' as TypeEnum,
		INTERNALERROR: 'INTERNAL_ERROR' as TypeEnum,
		INFORMATION: 'INFORMATION' as TypeEnum,
		NOTFOUND: 'NOT_FOUND' as TypeEnum,
		CONFLICT: 'CONFLICT' as TypeEnum
	};
}