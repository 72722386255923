/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AdrWidget {
	tipo?: AdrWidget.TipoEnum;
	titolo?: string;
	descrizione?: string;
	payload?: any;
}

export namespace AdrWidget {
	export type TipoEnum =
		'CATEGORIE_MINACCE'
		| 'MINACCE_RISCHIOMASSIMO'
		| 'NUMEROREQUISITI_LAYER'
		| 'NUMEROREQUISITI_CLASSEFUNZIONALE'
		| 'ENTITA_LAYER'
		| 'INFORMAZIONE_IMPATTI'
		| 'RP_ANALISI'
		| 'VALORI_RID_INFO_WIDGET'
		| 'VALORI_RID_MINACCIA_WIDGET'
		| 'CONTROMISURE_LAYER'
		| 'CONTROMISURE_IMPLEMENTAZIONE'
		| 'CONTROMISURE_IMPLEMENTAZIONE_LAYER'
		| 'RE_ANALISI'
		| 'RISCHIO_LAYER';
	export const TipoEnum = {
		CATEGORIEMINACCE: 'CATEGORIE_MINACCE' as TipoEnum,
		MINACCERISCHIOMASSIMO: 'MINACCE_RISCHIOMASSIMO' as TipoEnum,
		NUMEROREQUISITILAYER: 'NUMEROREQUISITI_LAYER' as TipoEnum,
		NUMEROREQUISITICLASSEFUNZIONALE: 'NUMEROREQUISITI_CLASSEFUNZIONALE' as TipoEnum,
		ENTITALAYER: 'ENTITA_LAYER' as TipoEnum,
		INFORMAZIONEIMPATTI: 'INFORMAZIONE_IMPATTI' as TipoEnum,
		RPANALISI: 'RP_ANALISI' as TipoEnum,
		VALORIRIDINFOWIDGET: 'VALORI_RID_INFO_WIDGET' as TipoEnum,
		VALORIRIDMINACCIAWIDGET: 'VALORI_RID_MINACCIA_WIDGET' as TipoEnum,
		CONTROMISURELAYER: 'CONTROMISURE_LAYER' as TipoEnum,
		CONTROMISUREIMPLEMENTAZIONE: 'CONTROMISURE_IMPLEMENTAZIONE' as TipoEnum,
		CONTROMISUREIMPLEMENTAZIONELAYER: 'CONTROMISURE_IMPLEMENTAZIONE_LAYER' as TipoEnum,
		REANALISI: 'RE_ANALISI' as TipoEnum,
		RISCHIOLAYER: 'RISCHIO_LAYER' as TipoEnum
	};
}