/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OteSummaryEntry {
	id?: number;
	versionNumber?: string;
	description?: string;
	validFrom?: Date;
	validTo?: Date;
	idCompany?: number;
	idCreatedUser?: number;
	createdUserName?: string;
	createdUserSurname?: string;
}