/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {AutomatismoDTO} from '../model/automatismoDTO';
import {SuccessfulResponseBoolean} from '../model/successfulResponseBoolean';
import {SuccessfulResponseIterableAutomatismo} from '../model/successfulResponseIterableAutomatismo';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class AutomatismoControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * Cancella un automatismo dall&#x27;anagrafe
	 *
	 * @param idAutomatismo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteAutomatismo(idAutomatismo: number, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public deleteAutomatismo(idAutomatismo: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public deleteAutomatismo(idAutomatismo: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public deleteAutomatismo(idAutomatismo: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (idAutomatismo === null || idAutomatismo === undefined) {
			throw new Error('Required parameter idAutomatismo was null or undefined when calling deleteAutomatismo.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (idAutomatismo !== undefined && idAutomatismo !== null) {
			queryParameters = queryParameters.set('idAutomatismo', <any>idAutomatismo);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseBoolean>('delete', `${this.basePath}/api/v1/automatismo`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Modifica un automatismo all&#x27;anagrafe
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public editAutomatismo(body: AutomatismoDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public editAutomatismo(body: AutomatismoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public editAutomatismo(body: AutomatismoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public editAutomatismo(body: AutomatismoDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling editAutomatismo.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('put', `${this.basePath}/api/v1/automatismo`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Effettua il get della lista degli automatismi in anagrafica
	 *
	 * @param page numero di pagina
	 * @param size quantità degli elementi da richiedere
	 * @param idAutomatismo Imposta filtro su campo idAutomatismo
	 * @param idVuln Imposta filtro su campo idVuln
	 * @param tag Imposta filtro su campo tag
	 * @param catasi Imposta filtro su campo catasi
	 * @param infoCatasi Imposta filtro su campo infoCatasi
	 * @param owner Imposta filtro su campo owner
	 * @param descrizione Imposta filtro su campo descrizione
	 * @param noteDiLavoro Imposta filtro su campo noteDiLavoro
	 * @param applicabilita Imposta filtro su campo applicabilita
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllVulnerabilita(page?: number, size?: number, idAutomatismo?: number, idVuln?: string, tag?: string, catasi?: string, infoCatasi?: string, owner?: string, descrizione?: string, noteDiLavoro?: string, applicabilita?: boolean, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseIterableAutomatismo>;
	public getAllVulnerabilita(page?: number, size?: number, idAutomatismo?: number, idVuln?: string, tag?: string, catasi?: string, infoCatasi?: string, owner?: string, descrizione?: string, noteDiLavoro?: string, applicabilita?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseIterableAutomatismo>>;
	public getAllVulnerabilita(page?: number, size?: number, idAutomatismo?: number, idVuln?: string, tag?: string, catasi?: string, infoCatasi?: string, owner?: string, descrizione?: string, noteDiLavoro?: string, applicabilita?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseIterableAutomatismo>>;
	public getAllVulnerabilita(page?: number, size?: number, idAutomatismo?: number, idVuln?: string, tag?: string, catasi?: string, infoCatasi?: string, owner?: string, descrizione?: string, noteDiLavoro?: string, applicabilita?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (size !== undefined && size !== null) {
			queryParameters = queryParameters.set('size', <any>size);
		}
		if (idAutomatismo !== undefined && idAutomatismo !== null) {
			queryParameters = queryParameters.set('idAutomatismo', <any>idAutomatismo);
		}
		if (idVuln !== undefined && idVuln !== null) {
			queryParameters = queryParameters.set('idVuln', <any>idVuln);
		}
		if (tag !== undefined && tag !== null) {
			queryParameters = queryParameters.set('tag', <any>tag);
		}
		if (catasi !== undefined && catasi !== null) {
			queryParameters = queryParameters.set('catasi', <any>catasi);
		}
		if (infoCatasi !== undefined && infoCatasi !== null) {
			queryParameters = queryParameters.set('infoCatasi', <any>infoCatasi);
		}
		if (owner !== undefined && owner !== null) {
			queryParameters = queryParameters.set('owner', <any>owner);
		}
		if (descrizione !== undefined && descrizione !== null) {
			queryParameters = queryParameters.set('descrizione', <any>descrizione);
		}
		if (noteDiLavoro !== undefined && noteDiLavoro !== null) {
			queryParameters = queryParameters.set('noteDiLavoro', <any>noteDiLavoro);
		}
		if (applicabilita !== undefined && applicabilita !== null) {
			queryParameters = queryParameters.set('applicabilita', <any>applicabilita);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<SuccessfulResponseIterableAutomatismo>('get', `${this.basePath}/api/v1/automatismo`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Aggiunge un automatismo all&#x27;anagrafe
	 *
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public makeNewAutomatismo(body: AutomatismoDTO, observe?: 'body', reportProgress?: boolean): Observable<SuccessfulResponseBoolean>;
	public makeNewAutomatismo(body: AutomatismoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessfulResponseBoolean>>;
	public makeNewAutomatismo(body: AutomatismoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessfulResponseBoolean>>;
	public makeNewAutomatismo(body: AutomatismoDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling makeNewAutomatismo.');
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<SuccessfulResponseBoolean>('post', `${this.basePath}/api/v1/automatismo`,
			{
				body: body,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
