import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {interval, Subject} from 'rxjs';
import {NbAuthJWTToken} from '../../../auth/services/token/token';
import {NbAuthService} from '../../../auth/services/auth.service';
import {Company} from '../../../@core/models/company';
import {Globals} from '../../../globals';
import {UserApiService} from '../../../@core/data/user-api.service';
import {NbAclService} from '../../../security/services/acl.service';
import {ApiResponse} from '../../../@core/models/apiresponse';
import {RichiestaControllerService} from '../../../@core/apis';
import 'rxjs-compat/add/observable/interval';
import {environment} from '../../../../environments/environment';

@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

	isFullscreen = false;
	elem;
	userPictureOnly: boolean = false;
	user = {fullname: '', picture: 'assets/images/nick.png', companyId: '', id: null};
	companies: Company[];
	selectedCompanyId: any;
	showCompaniesList = false;
	currentTheme = 'default';
	userMenu = [
		{title: 'Cambia Password', link: '/auth/reset-password'},
		{title: 'Logout', link: '/auth/logout'},
	];
	numRichieste: number = 0;
	private destroy$: Subject<void> = new Subject<void>();
	private strategyName: string;
	private sourceRichieste = interval(environment.REFRESH_TIME_RICHIESTE);

	constructor(private sidebarService: NbSidebarService,
							private menuService: NbMenuService,
							private themeService: NbThemeService,
							private userService: UserApiService,
							private layoutService: LayoutService,
							public aclService: NbAclService,
							public richiesteService: RichiestaControllerService,
							public globals: Globals,
							private cdr: ChangeDetectorRef,
							private authService: NbAuthService,
							private breakpointService: NbMediaBreakpointsService) {
	}

	ngOnInit() {
		this.currentTheme = this.themeService.currentTheme;

		this.sourceRichieste.pipe(
			takeUntil(this.destroy$)
		).subscribe((val) => {
			this.loadRichieste();
		});

		this.authService.onTokenChange()
			.pipe(takeUntil(this.destroy$))
			.subscribe((token: NbAuthJWTToken) => {
				if (token.isValid()) {
					this.user.fullname = token.getPayload().username;
					this.user.companyId = token.getPayload().id_company;
					this.selectedCompanyId = this.user.companyId;
					this.strategyName = token.getOwnerStrategyName();
					this.user.id = token.getPayload().id;
					this.loadRichieste();
				}
			});


		const {xl} = this.breakpointService.getBreakpointsMap();
		this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

		this.themeService.onThemeChange()
			.pipe(
				map(({name}) => name),
				takeUntil(this.destroy$),
			).subscribe(themeName => this.currentTheme = themeName);

		if (this.aclService.canCurrent('ALL_ALL')) {
			this.loadCompanies();
		} else {
			this.userService.getCompany(this.user.companyId).subscribe((res: ApiResponse<Company>) => {
					this.companies = [res.data];
					if (this.companies.length > 1) {
						this.showCompaniesList = true;
						this.cdr.detectChanges();
					}
				},
				err => {
					this.showCompaniesList = false;
				},
			);
		}

	}

	private loadRichieste() {
		this.richiesteService.getLeMieRichieste().subscribe(res => {
			this.numRichieste = res.data.requisitiApp.length + res.data.requisitiAsset.length + res.data.requisitiProcessi.length;
			this.cdr.detectChanges();
		})
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	changeTheme(themeName: string) {
		this.themeService.changeTheme(themeName);
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		this.layoutService.changeLayoutSize();

		return false;
	}

	navigateHome() {
		this.menuService.navigateHome();
		return false;
	}

	loadCompanies() {
		this.userService.getCompanyList(0, 10000, this.globals.COMPANY_STATUS_ACTIVE)
			.subscribe(res => {
					this.companies = res.data.content;
					this.showCompaniesList = true;
					this.cdr.detectChanges();
				},
				err => {
					this.showCompaniesList = false;
				}, () => {
				});
	}

	toggleFullscreen() {
		// Trigger fullscreen
		const document: any = window.document;
		this.elem = document.documentElement;
		const isFullscreen = document.documentElement as HTMLElement & {
			mozRequestFullScreen(): Promise<void>;
			webkitRequestFullscreen(): Promise<void>;
			msRequestFullscreen(): Promise<void>;
		};

		if (
			document.fullscreenEnabled ||
			document.webkitFullscreenEnabled ||
			document.mozFullScreenEnabled ||
			document.msFullscreenEnabled
		) {
			if (!this.isFullscreen) {
				if (this.elem.requestFullscreen) {
					this.elem.requestFullscreen();
					this.isFullscreen = true;
					return;
				} else if (this.elem.webkitRequestFullscreen) {
					this.elem.webkitRequestFullscreen();
					this.isFullscreen = true;
					return;
				} else if (this.elem.mozRequestFullScreen) {
					this.elem.mozRequestFullScreen();
					this.isFullscreen = true;
					return;
				} else if (this.elem.msRequestFullscreen) {
					this.elem.msRequestFullscreen();
					this.isFullscreen = true;
					return;
				}
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen();
					this.isFullscreen = false;
					return;
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
					this.isFullscreen = false;
					return;
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
					this.isFullscreen = false;
					return;
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
					this.isFullscreen = false;
					return;
				}
			}
		}
	}

	onCurrentCompanyChange(value: any) {
		this.authService.refreshToken(this.strategyName, this.selectedCompanyId).subscribe(res => {
		});
	}

}
