/**
 * Analisi del rischio
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {UserPrincipal} from '../model/userPrincipal';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class PrivilegeControllerService {

	protected basePath = 'http://localhost:5003';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 *
	 *
	 * @param idRole
	 * @param idPrivilege
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public assign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public assign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public assign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public assign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (idRole === null || idRole === undefined) {
			throw new Error('Required parameter idRole was null or undefined when calling assign.');
		}

		if (idPrivilege === null || idPrivilege === undefined) {
			throw new Error('Required parameter idPrivilege was null or undefined when calling assign.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (idRole !== undefined && idRole !== null) {
			queryParameters = queryParameters.set('idRole', <any>idRole);
		}
		if (idPrivilege !== undefined && idPrivilege !== null) {
			queryParameters = queryParameters.set('idPrivilege', <any>idPrivilege);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<any>('post', `${this.basePath}/privilege/assign`,
			{
				body: body,
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 *
	 *
	 * @param userPrincipal
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRoleAndPrivileges(userPrincipal: UserPrincipal, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public getRoleAndPrivileges(userPrincipal: UserPrincipal, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public getRoleAndPrivileges(userPrincipal: UserPrincipal, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public getRoleAndPrivileges(userPrincipal: UserPrincipal, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (userPrincipal === null || userPrincipal === undefined) {
			throw new Error('Required parameter userPrincipal was null or undefined when calling getRoleAndPrivileges.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (userPrincipal !== undefined && userPrincipal !== null) {
			queryParameters = queryParameters.set('userPrincipal', <any>userPrincipal);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<any>('get', `${this.basePath}/privilege/get`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 *
	 *
	 * @param userPrincipal
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public list(userPrincipal: UserPrincipal, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public list(userPrincipal: UserPrincipal, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public list(userPrincipal: UserPrincipal, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public list(userPrincipal: UserPrincipal, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (userPrincipal === null || userPrincipal === undefined) {
			throw new Error('Required parameter userPrincipal was null or undefined when calling list.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (userPrincipal !== undefined && userPrincipal !== null) {
			queryParameters = queryParameters.set('userPrincipal', <any>userPrincipal);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<any>('get', `${this.basePath}/privilege/privilege_list`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 *
	 *
	 * @param userPrincipal
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public privilegesRolesMatrix(userPrincipal: UserPrincipal, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public privilegesRolesMatrix(userPrincipal: UserPrincipal, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public privilegesRolesMatrix(userPrincipal: UserPrincipal, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public privilegesRolesMatrix(userPrincipal: UserPrincipal, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (userPrincipal === null || userPrincipal === undefined) {
			throw new Error('Required parameter userPrincipal was null or undefined when calling privilegesRolesMatrix.');
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (userPrincipal !== undefined && userPrincipal !== null) {
			queryParameters = queryParameters.set('userPrincipal', <any>userPrincipal);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [];

		return this.httpClient.request<any>('get', `${this.basePath}/privilege/privileges_roles_matrix`,
			{
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 *
	 *
	 * @param idRole
	 * @param idPrivilege
	 * @param body
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public unassign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe?: 'body', reportProgress?: boolean): Observable<any>;
	public unassign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
	public unassign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
	public unassign(idRole: number, idPrivilege: number, body?: UserPrincipal, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (idRole === null || idRole === undefined) {
			throw new Error('Required parameter idRole was null or undefined when calling unassign.');
		}

		if (idPrivilege === null || idPrivilege === undefined) {
			throw new Error('Required parameter idPrivilege was null or undefined when calling unassign.');
		}


		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (idRole !== undefined && idRole !== null) {
			queryParameters = queryParameters.set('idRole', <any>idRole);
		}
		if (idPrivilege !== undefined && idPrivilege !== null) {
			queryParameters = queryParameters.set('idPrivilege', <any>idPrivilege);
		}

		let headers = this.defaultHeaders;

		// authentication (bearerAuth) required
		if (this.configuration.accessToken) {
			const accessToken = typeof this.configuration.accessToken === 'function'
				? this.configuration.accessToken()
				: this.configuration.accessToken;
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}
		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		return this.httpClient.request<any>('delete', `${this.basePath}/privilege/unassign`,
			{
				body: body,
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
